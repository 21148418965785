import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'

export const jobAreasInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
    },
    jobAreas: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    currentArea : {},
    areaFormResponse : {},
    deleteResponse:{},
    areasOptList: [],
}

export const jobAreasSlice = createSlice({
    name: 'jobAreas',
    initialState: jobAreasInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setAreas: (state, action) => {
            state.jobAreas = action.payload.data
            state.loader = false
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setAreaFormSubmitted: (state, action) => {
            state.areaFormResponse = action.payload;
            state.loader = false;
        },
        setAreaDeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setCurrentArea: (state,action) => {
            state.currentArea = action.payload
        },
        setListOptionsAreas: (state, action) => {
            state.areasOptList = action.payload.data
            state.loader = false
        },
    },
})

export const getAreasList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('job/areas/list', data)
    dispatch(setAreas(response.data.data))
}

export const getAreasOptions = (data) => async (dispatch) => {
    const response = await clientService.post("job/areas/dropdown", data);
    dispatch(setListOptionsAreas(response.data));
};

export const addArea = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/areas", data);
    dispatch(setAreaFormSubmitted(response.data))
};

export const editArea = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("job/areas/"+data.id, data);
    dispatch(setAreaFormSubmitted(response.data))
    dispatch(setLoader(false));
};

export const deleteArea = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("job/areas/destroy/" + id);
    dispatch(setAreaDeleteResponse(response.data));
};

export const deleteAreaMultiple = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/areas/multiple-destroy" , data);
    dispatch(setAreaDeleteResponse(response.data))
};

export const {
    setAreas,
    filters,
    setLoader,
    setAreaFormSubmitted,
    setAreaDeleteResponse,
    setCurrentArea,
    setListOptionsAreas
} = jobAreasSlice.actions

export const jobAreasState = (state) => {
    return state.jobAreas
}

export default jobAreasSlice.reducer
