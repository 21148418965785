const config = {
   	apiUrl : process.env.REACT_APP_API_URL,
   	pdfUrl : process.env.REACT_APP_PDF_URL,
   	poURL : process.env.REACT_APP_PO_URL,
	  siteUrl : process.env.REACT_APP_SITE_URL,
	  siteLiveUrl : process.env.REACT_APP_LIVE_SITE_URL,
    rootSiteUrl : process.env.REACT_APP_ROOT_SITE_URL,
	  jobIdKey: process.env.REACT_APP_JOB_KEY,
	  deptIdKey: process.env.REACT_APP_DEPT_KEY,
    siteDomain: process.env.REACT_APP_SITE_DOMAIN,
    showLogoFix: process.env.REACT_APP_SITE_LOGO_FIX,
    isSaaSVersion: process.env.REACT_APP_IS_SAAS,
    techappName: process.env.REACT_APP_TECH_APP_NAME
}

export default config;

