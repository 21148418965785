import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'

export const areasInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
    },
    areas: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    currentArea : {},
    areaFormResponse : {},
    deleteResponse:{},
    listOptionsAreas: [],
}

export const areasSlice = createSlice({
    name: 'areas',
    initialState: areasInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setAreas: (state, action) => {
            state.areas = action.payload.data
            state.loader = false
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setAreaFormSubmitted: (state, action) => {
            state.areaFormResponse = action.payload;
            state.loader = false;
        },
        setAreaDeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setCurrentArea: (state,action) => {
            state.currentArea = action.payload
        },
        setListOptionsAreas: (state, action) => {
            state.listOptionsAreas = action.payload.data
            state.loader = false
        },
    },
})

export const getAreasList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('areas/list', data)
    dispatch(setAreas(response.data.data))
}

export const getAreasOptions = (data) => async (dispatch) => {
    const response = await clientService.post("areas/dropdown", data);
    dispatch(setListOptionsAreas(response.data));
};

export const addArea = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("areas", data);
    dispatch(setAreaFormSubmitted(response.data))
};

export const editArea = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("areas/"+data.id, data);
    dispatch(setAreaFormSubmitted(response.data))
    dispatch(setLoader(false));
};

export const deleteArea = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("areas/destroy/" + id);
    dispatch(setAreaDeleteResponse(response.data));
};

export const deleteAreaMultiple = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("areas/multiple-destroy" , data);
    dispatch(setAreaDeleteResponse(response.data))
};

export const {
    setAreas,
    filters,
    setLoader,
    setAreaFormSubmitted,
    setAreaDeleteResponse,
    setCurrentArea,
    setListOptionsAreas
} = areasSlice.actions

export const areasState = (state) => {
    return state.areas
}

export default areasSlice.reducer
