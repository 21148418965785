import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'

export const jobSubsystemsInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
    },
    jobSubsystems: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    currentSubsytem : {},
    formResponse : {},
    deleteResponse:{},
    importCsvRes:{},
    changeAreaAndFloor:{},
    subSytemsOptList: []
}

export const jobSubsystemsSlice = createSlice({
    name: 'jobSubsystems',
    initialState: jobSubsystemsInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setSubsystems: (state, action) => {
            state.jobSubsystems = action.payload.data
            state.loader = false
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setSubsytemFormSubmitted: (state, action) => {
            state.formResponse = action.payload;
            state.loader = false;
        },
        setSubsytemDeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setCurrentSubsytem: (state,action) => {
            state.currentSubsytem = action.payload
        },
        setListOptionsSubsytems: (state, action) => {
            state.subSytemsOptList = action.payload.data
            state.loader = false
        },
        setImportCsvRes: (state, action) => {
            state.importCsvRes = action.payload;
            state.loader = false;
        },
        setChangeAreaAndFloor: (state, action) => {
            state.changeAreaAndFloor = action.payload;
            state.loader = false;
        }
    },
})

export const getSubsystemsList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('job/subsystems/list', data)
    dispatch(setSubsystems(response.data.data))
}

export const getSubsystemsOptions = (data) => async (dispatch) => {
    const response = await clientService.post("job/subsystems/dropdown", data);
    dispatch(setListOptionsSubsytems(response.data));
};

export const addSubsystem = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/subsystems", data);
    dispatch(setSubsytemFormSubmitted(response.data))
};

export const editSubsystem = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("job/subsystems/"+data.id, data);
    dispatch(setSubsytemFormSubmitted(response.data))
    dispatch(setLoader(false));
};

export const importCsvRecords = (data, file) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.fileUpload('job/subsystems/importcsv', data, file)
    dispatch(setLoader(false))
    dispatch(setImportCsvRes(response?.data))
};

export const deleteSubsytem = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("job/subsystems/destroy/" + id);
    dispatch(setSubsytemDeleteResponse(response.data));
};

export const deleteSubsytemMultiple = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/subsystems/multiple-destroy", data);
    dispatch(setSubsytemDeleteResponse(response.data))
};

export const changeAreaAndFloorName = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/subsystems/change-area-floor-name", data);
    dispatch(setChangeAreaAndFloor(response.data))
    dispatch(setLoader(false));
};

export const {
    setSubsystems,
    filters,
    setLoader,
    setSubsytemFormSubmitted,
    setSubsytemDeleteResponse,
    setImportCsvRes,
    setCurrentSubsytem,
    setListOptionsSubsytems,
    setChangeAreaAndFloor
} = jobSubsystemsSlice.actions

export const jobSubsystemsState = (state) => {
    return state.jobSubsystems
}

export default jobSubsystemsSlice.reducer
