import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

import clientService from '../../services/service'
import { getCategory } from './budgetCategorySlice'
let listApiController, totalListController;

export const orderInitialState = {
  loading: false,
  filter: {
    page: 1,
    size: 10,
    sort_by: 'requisite_number',
    sort_order: 'desc',
    advance: {
      date_from: '',
      date_to: '',
      keyword: '',
      purchase_order_number: '',
      material_order_number: '',
    },
    departnmentIds : [],
    job : ""
  },
  currentOrder: {},
  statistics: {
    allOrder: 0,
    partialRelease: 0,
    pendingRelease: 0,
    complete: 0,
  },
  orders: {
    totalPages: 1,
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data: [],
  },
  addOrderFormResponse: {},
  addOrderNoteFormResponse: {},
  deleteResponse: {},
  attachmentDeleteResponse: {},
  emailDeleteResponse: {},
  closeResponse: {},
  emailResponse: [],
  isOrderSaved: false,
  copyEmail :[],
  copyOrderResposnse :[],
  pdf :[],
  attechments : [],
  nextOrderId : 0,
  preOrderId : 0,
  lastOrderAttention : {},
  refreshMatOrder: (new Date()).toString(),
}

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: orderInitialState,
  reducers: {
    setNextPreOrder: (state, action) => {
      state.nextOrderId = action.payload.nextOrderId
      state.preOrderId = action.payload.preOrderId
    },
    filters: (state, action) => {
      state.filter = action.payload
      state.loader = true
    },
    setAttechment: (state, action) => {
      state.attechments = action.payload
    },
    updateOrder: (state, action) => {
      state.currentOrder = action.payload
      state.isOrderSaved = false
    },
    setMatOrderRefresh: (state, action) => {
      state.refreshMatOrder = (new Date()).toString()
    },
    updateOders: (state, action) => {
      state.addOrderFormResponse = {}
      state.orders = action.payload.data
      state.loader = false
      state.isOrderSaved = false
    },
    setOrderFormSubmitted: (state, action) => {
      state.addOrderFormResponse = action.payload.data
      state.loader = false
      state.isOrderSaved = true
    },
    resetOrderFormSubmitted: (state, action) => {
      state.addOrderFormResponse = {}
      state.loader = false
    },
    setOrderNoteFormSubmitted: (state, action) => {
      state.addOrderNoteFormResponse = action.payload
      state.loader = false
    },
    setOrderDeleteResponse: (state, action) => {
      state.deleteResponse = action.payload
      state.loader = false
    },
    setAttachmentDeleteResponse: (state, action) => {
      state.attachmentDeleteResponse = action.payload
    },
    setOrderCloseResponse: (state, action) => {
      state.closeResponse = action.payload
      state.loader = false
    },
    setEmailDistributionResponse: (state, action) => {
      state.emailResponse = action.payload.data
      state.loader = false
    },
    setCopyEmailJobResponse: (state, action) => {
      state.copyEmail = action.payload.data
      state.loader = false
    },
    setCopyOrderResponse: (state, action) => {
      state.copyOrderResposnse = action.payload
      state.loader = false
    },
    setEmailDistributionDeleteResponse: (state, action) => {
      state.emailDeleteResponse = action.payload.data
      state.loader = false
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    updateStatistics: (state, action) => {
      state.statistics = action.payload.data
    },
    setCurrentOrder: (state, action) => {
      var order = action.payload
      const address = action.payload.address
      order.address = address?.address
      order.city = address?.city
      order.state = address?.state
      order.zip = address?.zip
      state.currentOrder = order;
      state.loader  = false;
      state.attechments = action.payload?.attachments??[];

    },
    setSaveEmailsResponse: (state, action) => {
      state.saveEmailsResponse = action.payload
      state.loader = false
    },
    setViewPdf: (state, action) => {
      state.pdf = action.payload
      state.loader = false
    },
    setLastAttention : (state, action) => {
      
      state.lastOrderAttention = action.payload
    }
  },
})

export const setFilter = (filter) => async (dispatch) => {
  dispatch(filters(filter))
}

export const getOrders = (filter) => async (dispatch) => {
  if (listApiController) {
    listApiController.abort()
  }
  listApiController = new AbortController();
  dispatch(filters(filter))
  dispatch(setLoader(true))
  // dispatch(setCurrentOrder({}));
  var manaegeFilter = {...filter};
  if(manaegeFilter.job == -1){
    manaegeFilter.job = "";
  }


  if(manaegeFilter && manaegeFilter.departnmentIds){
    manaegeFilter.departnmentIds =  [...manaegeFilter.departnmentIds];
    for(var i = 0; i < manaegeFilter.departnmentIds.length; i++){
      if(!manaegeFilter.departnmentIds[i]){
        manaegeFilter.departnmentIds[i] = 'NULL';
      }
      if(manaegeFilter.departnmentIds[i] == 'all'){
        manaegeFilter.departnmentIds[i] = -1;
      }
    };
  };
  if (manaegeFilter.job == 'all') {
    manaegeFilter.job = -1;
  }
  if (manaegeFilter.job == 'allJo') {
    manaegeFilter.job = -3;
  }
  if (manaegeFilter.job == 'allWo') {
    manaegeFilter.job = -4;
  }
  const response = await clientService.post('orders/list', manaegeFilter, {signal:  listApiController.signal})
  if (response && response.data) {
    dispatch(updateOders(response.data.data))
  }
}

export const addOrders = (newOrderData) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('orders', newOrderData)
  dispatch(setOrderFormSubmitted(response.data))
}

export const addOrderNotes = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('order-note', data)
  dispatch(setOrderNoteFormSubmitted(response.data));
}

export const editOrders = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('orders/' + data.id, data.value)
  dispatch(setOrderFormSubmitted(response.data))
  dispatch(setLoader(false))
}

export const deleteOrder = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('orders/destroy/' + id)
   dispatch(setOrderDeleteResponse(response.data))
   
}

export const deleteOrderMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('orders/multiple-destroy', data)
  dispatch(setOrderDeleteResponse(response.data))
}

export const closeOrderMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('orders/close-order', data)
  dispatch(setOrderCloseResponse(response.data))
}

export const getOrder = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  dispatch(getCategory())
  const response = await clientService.get('orders/' + id)
  if (response.data.code === 200) {
    dispatch(setCurrentOrder(response.data.data.data));
    dispatch(setNextPreOrder({
      nextOrderId : response.data.data.nextOrderId,
      preOrderId: response.data.data.preOrderId
    })) 
  } else {
    alert('No Record Found')
    dispatch(setLoader(false))
  }
}
export const getTotalOrder = (data) => async (dispatch) => {
  if (totalListController) {
    totalListController.abort()
  }
  totalListController = new AbortController();
  dispatch(setLoader(true));
  var flt = {...data }
  if(data && data.departnmentIds){
    flt.departnmentIds =  [...data.departnmentIds];
    // flt.jobId =  [...data.jobId];
    for(var i = 0; i < data.departnmentIds.length; i++){
      if(!data.departnmentIds[i]){
        flt.departnmentIds[i] = 'NULL';
      }
      if(data.departnmentIds[i] == 'all'){
        flt.departnmentIds[i] = -1;
      }
    };
  };
  if (flt.jobId == 'allJo') {
    flt.jobId = -3;
  }
  if (flt.jobId == 'allWo') {
    flt.jobId = -4;
  }
  if (flt.jobId == 'all') {
    flt.jobId = -1;
  }
  
  const response = await clientService.post('order/total-order', flt, {signal: totalListController.signal})
  if (response && response.data && response.data.code === 200) {
    dispatch(updateStatistics(response.data))
  }
}

export const getEmailDistribution = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post(
    'material-email/email-distribution/',
    data
  )
  dispatch(setEmailDistributionResponse(response.data.data))
}

export const copyEmailFromJob = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material-email/copy',data);
  dispatch(setCopyEmailJobResponse(response.data.data));
  dispatch(getEmailDistribution({job_id : data.toId}));
}

export const copyMaterialOrder = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('copy-order',data);
  dispatch(setCopyOrderResponse(response.data));
}

export const saveEmails = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material-email/save-list/', data)
  dispatch(setSaveEmailsResponse(response.data.data))
}

export const deleteEmailDistribution = (id, job_id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('material-email/destroy/' + id);
  dispatch(setEmailDistributionDeleteResponse(response.data));
  dispatch(getEmailDistribution({job_id : job_id}));
}

export const attachment = (data, file) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.pdfUpload(
    'orders/attachment',
    data,
    file
  )
  dispatch(setAttechment(response.data.data.orderAttachments));
  dispatch(setLoader(false))
}

export const removeAttachment = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('order-attachment/' + id);
  dispatch(setAttechment(response.data.data))
  dispatch(setLoader(false))
}

export const viewPdf = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('order/pdf', data)
  dispatch(setViewPdf(response.data.data))
  window.open(config.pdfUrl + response.data.data)

  dispatch(setLoader(false))
}

export const getLastAttention = (vendorId, orderId) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('last-vendor/', {
    vendor_id : vendorId,
    order_id : orderId
  });
  if(response.data?.data?.lastAttention){
    dispatch(setLastAttention(response.data.data.lastAttention))
  }
  dispatch(setLoader(false))
}

export const {
  updateOrder,
  updateOders,
  updateStatistics,
  filters,
  setOrderDeleteResponse,
  setOrderCloseResponse,
  setEmailDistributionResponse,
  setOrderFormSubmitted,
  setOrderNoteFormSubmitted,
  setCurrentOrder,
  setSaveEmailsResponse,
  resetOrderFormSubmitted,
  setEmailDistributionDeleteResponse,
  setCopyOrderResponse,
  setLoader,
  setNextPreOrder,
  setCopyEmailJobResponse,
  setViewPdf,
  setAttechment,
  setAttachmentDeleteResponse,
  setLastAttention,
  setMatOrderRefresh
} = ordersSlice.actions

export const ordersState = (state) => {
  return state.orders
}

export default ordersSlice.reducer
