import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'
var FileSaver = require('file-saver');

export const materialInitialState = {
  loading: false,
  filter: {
    page: 1,
    size: 10,
    sort_by: '',
    sort_order: 'desc',
    isBuyoutPid: 1,
  },
  currentMaterial: {},
  materials: {
    totalPages: 1,
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data: [],
  },
  dropdown: [],
  importResponse: {},
  addMaterialDatabaseFormResponse: {},
  deleteResponse: {},
  dropdownLoader: false,
  csvExportData :[],
  materialLoaded: false,
  copyMaterial : {},
  uomDropdown: [],
  addMaterialDatabaseFormResponseUOM: {},
  inactiveMaterial : {},
}

export const materialSlice = createSlice({
  name: 'materials',
  initialState: materialInitialState,
  reducers: {
    filters: (state, action) => {
      state.filter = action.payload
      state.loader = true
    },
    updateMaterial: (state, action) => {
      state.currentMaterial = action.payload
    },
    updateMaterials: (state, action) => {
      state.addMaterialDatabaseFormResponse = {}
      state.materials = action.payload.data
      state.loader = false
    },
    setMaterialFormSubmitted: (state, action) => {
      state.addMaterialDatabaseFormResponse = action.payload
      state.loader = false
    },
    setMaterialDeleteResponse: (state, action) => {
      state.deleteResponse = action.payload
      state.loader = false
    },
    setImportMaterialResponse: (state, action) => {
      state.importResponse = action.payload;
      state.loader = false;
    },
    setCurrentMaterial: (state, action) => {
      state.currentMaterial = action.payload
    },
    setCopyMaterial: (state, action) => {
      state.copyMaterial = action.payload;
      state.loader = false;
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setDropdownLoader: (state, action) => {
      state.dropdownLoader = action.payload
    },
    setDropddownData: (state, action) => {
      state.dropdown = action.payload.data
      state.dropdownLoader = false
    },
    setCsvExportData : (state, action) => {
      state.csvExportData = action.payload.data;
      state.materialLoaded = true;
      state.loader = false;
    },
    setUOMDropdownData: (state, action) => {
      state.uomDropdown = action.payload.data
    },
    setCurrentMaterialUOM: (state, action) => {
      state.currentMaterial = action.payload
    },
    setMaterialFormSubmittedUOM: (state, action) => {
      state.addMaterialDatabaseFormResponseUOM = action.payload
      state.loader = false
    },
    setInactiveMaterial: (state, action) => {
      state.inactiveMaterial = action.payload;
      state.loader = false;
    },
  },
})

export const getMaterials = (filter) => async (dispatch) => {
  dispatch(filters(filter))
  dispatch(setLoader(true))
  dispatch(setCurrentMaterial({}))
  let data = {...filter}
  data.isBuyoutPid = 1;
  const response = await clientService.post('material/list', data)
  dispatch(updateMaterials(response.data.data))
}

export const addMaterials = (newVendorData) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material', newVendorData)
  dispatch(setMaterialFormSubmitted(response.data))
}

export const editSaveMaterialsUOM = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('materialUOM/' + data.id, data.value)
  dispatch(setLoader(false))
}

export const editMaterialsUOM = (data, uomDropdown = []) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('materialUOM/' + data.id, data.value)
  const newUom = {id: data.id, unit_of_measure: response?.data?.data?.unit_of_measure, label: response?.data?.data?.unit_of_measure}
  uomDropdown.push(newUom)
  dispatch(setUOMDropdownData({data: uomDropdown}))
  dispatch(setMaterialFormSubmittedUOM(response.data))
  dispatch(setLoader(false))
}

export const editMaterials = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('material/' + data.id, data.value)
  dispatch(setMaterialFormSubmitted(response.data))
  dispatch(setLoader(false))
}
export const materialInactive = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material/makeInactive', data)
  dispatch(setInactiveMaterial(response.data));
}
export const deleteMaterial = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('material/destroy/' + id)
  dispatch(setMaterialDeleteResponse(response.data))
}
export const deleteMaterialMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material/multiple-destroy', data)
  dispatch(setMaterialDeleteResponse(response.data))
}
export const importMaterialList = (data, file) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.fileUpload('material/upload', data, file)
  dispatch(setLoader(false))
  dispatch(setImportMaterialResponse(response.data))
}
export const getMaterialDropdown = (data, resolver) => async (dispatch) => {
  dispatch(setDropdownLoader(true))
  const response = await clientService.post('material/dropdwon', data)
  dispatch(setDropddownData(response.data.data));
  let itemList = response.data.data.data;
  resolver((itemList) ? itemList : []);
  dispatch(setDropdownLoader(false))
}

export const getMaterialUOMDropdown = (data, resolver, selectedItemId = []) => async (dispatch) => {
  const response = await clientService.post('material/uomDropdown', data)
  dispatch(setUOMDropdownData(response.data.data));
  let itemList = response.data.data.data;
  if (selectedItemId.length && response.data && response.data.data && response.data.data.data) {
    itemList = response.data.data.data.filter(a => selectedItemId.indexOf(a.id) == -1)
  }
  resolver((itemList) ? itemList : []);
}

export const materialList = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("material-list", data);
    dispatch(setLoader(false));
    //dispatch(setCsvExportData(response.data.data));
    if(response?.data) {
        var blob;
        if(response?.data?.code == 400) {
            blob = new Blob(['No Record found'], {type: "text/csv;charset=utf-8"});
        } else {
            blob = new Blob([response.data], {type: "text/csv;charset=utf-8"})
        }
        FileSaver.saveAs(blob, "material_db_csv.csv");
    }
};

export const copyFromAnotherJob = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("material/copy", data);
  dispatch(setCopyMaterial(response.data));
}

export const {
  updateMaterial,
  updateMaterials,
  filters,
  setMaterialDeleteResponse,
  setMaterialFormSubmitted,
  setCurrentMaterial,
  setLoader,
  setDropdownLoader,
  setDropddownData,
  setCsvExportData,
  setImportMaterialResponse,
  setCopyMaterial,
  setUOMDropdownData,
  setCurrentMaterialUOM,
  setMaterialFormSubmittedUOM,
  setInactiveMaterial
} = materialSlice.actions

export const materialsState = (state) => {
  return state.materials
}

export default materialSlice.reducer
