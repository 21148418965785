import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'
import FileSaver from 'file-saver'

export const budgetSummaryInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: 'budget_id',
        sort_order: 'asc',
        filter_budget: '',
        filter_publish_date: '',
        filter_start_date: '',
        filter_end_date: '',
        publishedId: 0
    },
    reportings: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    }
}

export const budgetSummarySlice = createSlice({
    name: 'budgetSummary',
    initialState: budgetSummaryInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setReportings: (state, action) => {
            state.reportings = action?.payload?.data
            state.loader = false
        }
    },
})

export const getBudgetSummaryList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('budgetSummary/list', data)
    dispatch(setReportings(response?.data?.data))
}

export const getBudgetSummaryCSV = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("budgetSummary/csv", data);
    if (response?.data) {
        var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "budget_summary_csv.csv");
    }
    dispatch(setLoader(false));
};

export const {
    filters,
    setLoader,
    setReportings,
    setPublishResponse,
    setPublishLogRecords,
    setDeleteFieldDataResponse
} = budgetSummarySlice.actions

export const budgetSummaryState = (state) => {
    return state.budgetSummary
}

export default budgetSummarySlice.reducer
