import { createSlice } from '@reduxjs/toolkit'

import clientService from '../../services/service'
let obortApiController

export const employeeInitialState = {
    loading: false,
    empPtoList: [],
    empAvailablePto: [],
}

export const employeeSlice = createSlice({
    name: 'employees',
    initialState: employeeInitialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setEmptoList:(state, action) => {
            state.empPtoList = action.payload.data
        },
        setEmpAvailablePTO:(state, action) => {
            state.empAvailablePto = action.payload.data
        }
    },
})

export const getEmpOptionsList = () => async (dispatch) => {
    if (obortApiController) {
        obortApiController.abort()
    }
    obortApiController = new AbortController();
    dispatch(setLoader(true))
    const response = await clientService.get("employee/get-emp-options", {signal:  obortApiController.signal});
    dispatch(setEmptoList(response.data));
};

export const getAvailablePTO = (dateData) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post("employee/get-avaiable-pto",dateData);
    dispatch(setEmpAvailablePTO(response.data));
    dispatch(setLoader(false))
};

export const {
    setLoader,
    setEmptoList,
    setEmpAvailablePTO
} = employeeSlice.actions

export const employeeState = (state) => {
    return state.employees
}

export default employeeSlice.reducer