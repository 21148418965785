import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';

export const costCodeInitialState = {
    loading: false,
    costCodeList: [],
    floorCCodeList: []
};

export const costCodeSlice = createSlice({
    name: 'costCodes',
    initialState: costCodeInitialState,
    reducers: {
        updateCostCode: (state, action) => {
            state.costCodeList = action.payload;
        },
        setFloorCCodeList: (state, action) => {
            state.floorCCodeList = action.payload;
        },
        setLoader: (state,action) => {
            state.loader = action.payload
        },
    },
});

export const getCostCodes = (data) => async (dispatch) => {
    const response = await clientService.get("costcode-list/"+data);
    dispatch(updateCostCode(response.data.data));
};

export const getFloorCostCodes = (data) => async (dispatch) => {
    const response = await clientService.post("floor-cc-list/", data);
    dispatch(setFloorCCodeList(response.data.data));
};

export const { updateCostCode, setFloorCCodeList, setLoader } = costCodeSlice.actions;

export const costCodeState = (state) => {
    return state.costCodes;
};

export default costCodeSlice.reducer;
