import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';

export const attentionInitialState = {
  loading: false,
  selectedAttentions:[],
  attentions: [],
  currentAttention : {},
  addAttentionFormResponse : {}
};

export const attentionSlice = createSlice({
  name: 'attentions',
  initialState: attentionInitialState,
  reducers: {
    selectedAttentions: (state, action) => {
      state.selectedAttentions = action.payload;
    },
    updateAttentions: (state, action) => {
        state.attentions = action.payload;
    },
    setCurrentAttention: (state,action) => {
      state.currentAttention = action.payload
    },
    setLoader: (state,action) => {
      state.loader = action.payload
    },
    setAttentionFormSubmitted: (state, action) => {
      state.addAttentionFormResponse = action.payload;
      state.loader = false;
  },
  },
});

export const getAttention = (data, resolver) => async (dispatch) => {
  const response = await clientService.post("attention/dropdown", data);
  dispatch(updateAttentions(response.data.data));
  resolver(response.data.data);
};

export const selectAttentionRedux = (data) => async (dispatch) => {
  dispatch(selectedAttentions(data));
};

export const addAttentions = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("attention", data);
  dispatch(setAttentionFormSubmitted(response.data))
};

export const { updateAttentions, selectedAttentions, setCurrentAttention, setLoader, setAttentionFormSubmitted } = attentionSlice.actions;

export const attentionsState = (state) => {
    return state.attentions;
};

export default attentionSlice.reducer;
