import { createSlice } from "@reduxjs/toolkit"
import { getParams } from "common/Common"
import clientService from "services/service"

export const locationInitialState = {
	loading: false,
	locations: []
}

export const locationSlice = createSlice({
	name: 'locations',
	initialState: locationInitialState,
	reducers: {
		updateLocation: (state, action) => {
			state.locations = action.payload
		}
	}
})

export const getLocation = (params) => async (dispatch) => {
	let param = getParams(params)
	const res = await clientService.get('location/list' + param)
	let loc = [];
	if (res.data && res.data.data && res.data.data.length) {
		res.data.data.map((obj) => { loc.push({value: obj.id, label: obj.location})})
	}
	dispatch(updateLocation(loc))
}

export const { updateLocation } = locationSlice.actions;
export const locationState = (state) => {
    return state.locations;
};
export default locationSlice.reducer;