import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

import clientService from '../../services/service'
import { getCategory } from './budgetCategorySlice'
import { getLocation } from './locationSlice'
import { getMaterialUOMDropdown } from './materialSlice'
var FileSaver = require('file-saver');

let listApiController, totalListController;

export const orderInitialState = {
  loading: false,
  filter: {
    page: 1,
    size: 10,
    sort_by: 'requisite_number',
    sort_order: 'desc',
    keyword: '',
    advance: {
      date_from: '',
      date_to: '',
      keyword: '',
      purchase_order_number: '',
      material_order_number: '',
      buyout_order_status: 'All',
    },
    departnmentIds : [],
    job : ""
  },
  currentOrder: {},
  statistics: {
    allOrder: 0,
    openOrder: 0,
    tempOrder: 0,
    complete:0
  },
  orders: {
    totalPages: 1,
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data: [],
    userList:[]
  },
  addOrderFormResponse: {},
  addOrderNoteFormResponse: {},
  deleteResponse: {},
  attachmentDeleteResponse: {},
  emailDeleteResponse: {},
  closeResponse: {},
  emailResponse: [],
  emailSendResponse: {},
  isOrderSaved: false,
  isOrderItemRelease: false,
  isReleaseReceived: false,
  isOrderReopen: false,
  refreshOrder: (new Date()).toString(),
  afterUpdateField: '',
  copyEmail :[],
  copyOrderResposnse :[],
  pdf :[],
  importResponse: {},
  attechments : [],
  nextOrderId : 0,
  preOrderId : 0,
  nextReleaseNo: '',
  lastOrderAttention : {},
  orderItemDetails : [],
  orderReleases : [],
  isOrderItemDetailsSave: false,
  latestReleaseNo: null,
  updateVendorResponse: {},
  updatePOResponse: {}
}

export const buyoutOrdersSlice = createSlice({
  name: 'buyoutOrders',
  initialState: orderInitialState,
  reducers: {
    setNextPreOrder: (state, action) => {
      state.nextOrderId = action.payload.nextOrderId
      state.preOrderId = action.payload.preOrderId
      state.nextReleaseNo = action.payload.nextReleaseNo
    },
    filters: (state, action) => {
      state.filter = action.payload
      state.loader = true
    },
    setAttechment: (state, action) => {
      state.attechments = action.payload
    },
    updateOrder: (state, action) => {
      state.currentOrder = action.payload
      state.isOrderSaved = false
    },
    updateOders: (state, action) => {
      state.addOrderFormResponse = {}
      state.orders = action.payload.data
      state.userList = action.payload.data.userList
      state.loader = false
      state.isOrderSaved = false
    },
    setOrderFormSubmitted: (state, action) => {
      state.addOrderFormResponse = action.payload.data
      state.loader = false
      state.isOrderSaved = true
    },
    setOrderReleaseInit: (state, action) => {
      state.isOrderItemRelease = false
    },
    setOrderReleaseResponse: (state, action) => {
      state.loader = false
      state.orderReleases = action.payload
    },
    setOrderReleaseSubmitted: (state, action) => {
      //state.addOrderFormResponse = action.payload?.data
      state.loader = false
      state.isOrderItemRelease = true
    },
    setOrderRopenInit: (state, action) => {
      state.isOrderReopen = false
    },
    setReleaseReceived: (state, action) => {
      state.isReleaseReceived = action.payload
    },
    setOrderRefresh: (state, action) => {
      state.refreshOrder = (new Date()).toString()
    },
    setAfterUpdateFieldInit: (state, action) => {
      state.afterUpdateField = action.payload
    },
    setOrderReopenSubmitted: (state, action) => {
      state.loader = false
      state.isOrderReopen = true
    },
    setOrderItemsDetails: (state, action) => {
      state.orderItemDetails = action.payload.data
      state.loader = false
    },
    resetOrderFormSubmitted: (state, action) => {
      state.addOrderFormResponse = {}
      state.loader = false
    },
    setOrderNoteFormSubmitted: (state, action) => {
      state.addOrderNoteFormResponse = action.payload
      state.loader = false
    },
    setOrderDeleteResponse: (state, action) => {
      state.deleteResponse = action.payload
      state.loader = false
    },
    setAttachmentDeleteResponse: (state, action) => {
      state.attachmentDeleteResponse = action.payload
    },
    setOrderCloseResponse: (state, action) => {
      state.closeResponse = action.payload
      state.loader = false
    },
    setEmailDistributionResponse: (state, action) => {
      state.emailResponse = action.payload.data
      state.loader = false
    },
    setEmailSendResponse: (state, action) => {
        state.emailSendResponse = action.payload;
        state.loader = false;
    },
    setCopyEmailJobResponse: (state, action) => {
      state.copyEmail = action.payload.data
      state.loader = false
    },
    setCopyOrderResponse: (state, action) => {
      state.copyOrderResposnse = action.payload
      state.loader = false
    },
    setEmailDistributionDeleteResponse: (state, action) => {
      state.emailDeleteResponse = action.payload.data
      state.loader = false
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    updateStatistics: (state, action) => {
      state.statistics = action.payload.data
    },
    setCurrentOrder: (state, action) => {
      var order = action.payload
      const address = action.payload.address
      order.address = address?.address
      order.city = address?.city
      order.state = address?.state
      order.zip = address?.zip
      order.full_address = address?.full_address
      state.currentOrder = order;
      state.loader  = false;
      state.attechments = action.payload?.attachments??[];

    },
    setSaveEmailsResponse: (state, action) => {
      state.saveEmailsResponse = action.payload
      state.loader = false
    },
    setViewPdf: (state, action) => {
      state.pdf = action.payload
      state.loader = false
    },
    setImportOrderResponse: (state, action) => {
        state.importResponse = action.payload;
        state.loader = false;
    },
    setLastAttention : (state, action) => {
        state.lastOrderAttention = action.payload
    },
    setOrderItemDetailsSaveFlag : (state, action) => {
        state.isOrderItemDetailsSave = action.payload;
    },
    setLatestReleaseNo : (state, action) => {
        state.latestReleaseNo = action.payload;
    },
    setUpdateVendorResponse : (state, action) => {
      state.updateVendorResponse = action.payload;
    },
    setUpdatePOResponse : (state, action) => {
      state.updatePOResponse = action.payload;
    },
  },
})

export const setFilter = (filter) => async (dispatch) => {
  dispatch(filters(filter))
}

export const getBuyoutOrders = (filter) => async (dispatch) => {
  if (listApiController) {
    listApiController.abort()
  }
  listApiController = new AbortController();
  dispatch(filters(filter))
  dispatch(setLoader(true))
  // dispatch(setCurrentOrder({}));
  var manaegeFilter = {...filter};
  if (manaegeFilter.job == 'all') {
    manaegeFilter.job = -1;
  }
  manaegeFilter['is_buyout'] = 1;

  if (manaegeFilter && manaegeFilter.departnmentIds) {
    manaegeFilter.departnmentIds =  [...manaegeFilter.departnmentIds];
    for (var i = 0; i < manaegeFilter.departnmentIds.length; i++) {
      if (!manaegeFilter.departnmentIds[i]) {
        manaegeFilter.departnmentIds[i] = 'NULL';
      }
      if(manaegeFilter.departnmentIds[i] == 'all'){
        manaegeFilter.departnmentIds[i] = -1;
      }
    };
  };
  if (manaegeFilter.job == 'allJo') {
    manaegeFilter.job = -3;
  }
  if (manaegeFilter.job == 'allWo') {
    manaegeFilter.job = -4;
  }
  const response = await clientService.post('buyout/orders/list', manaegeFilter, {signal:  listApiController.signal})
  if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.data) {
    response.data.data.data.data.map((obj) => {
      obj['descriptionSave'] = false
      obj['olddescription'] = obj.description ? obj.description : ''
      obj['old_purchase_order_number'] = obj.purchase_order_number ? obj.purchase_order_number : ''
    })
  }
  if (response) {
    dispatch(updateOders(response.data.data))
  }
}

export const addBuyoutOrders = (newOrderData) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/order/new', newOrderData)
  dispatch(setOrderFormSubmitted(response.data))
}

export const deleteReleaseItem = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  await clientService.put('buyout/deletereleaseitem', data)
  dispatch(setOrderRefresh())
}
export const releaseDelete = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const res = await clientService.put('buyout/releasedelete', data)
  if (!res?.status) {
    dispatch(setReleaseReceived(true))
    dispatch(setLoader(false))
  } else {
    dispatch(setOrderRefresh())
  }
}

export const updateOrderDescription = (data) => async (dispatch) => {
  await clientService.put('buyout/updatedescription', data)
}

export const updatePONumber = (data) => async (dispatch) => {
  const response = await clientService.put('buyout/updatePONumber', data)
  dispatch(setUpdatePOResponse(response.data));
  dispatch(setLoader(false))
}

export const saveVendorValue = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('buyout/changeVendor',data);
  dispatch(setUpdateVendorResponse(response.data));
  dispatch(setLoader(false))
}

export const updateOrderArea = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    await clientService.put('buyout/updateOrderArea',data)
    dispatch(setLoader(false))
}

export const addOrderNotes = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('order-note', data)
  dispatch(setOrderNoteFormSubmitted(response.data));
}

export const editOrders = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('buyout/order/' + data.id, data.value)
  dispatch(setOrderFormSubmitted(response.data))
  dispatch(setLoader(false))
}

export const updateOrderDetails = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('buyout/orderupdate/' + data.id, data)
  let res = (response?.data?.code == 400) ? 'exist' : 'update';
  dispatch(setAfterUpdateFieldInit({res: res, field: data.field, fieldType: data?.fieldType}))
  dispatch(setLoader(false))
}

export const saveOrderItemDetails = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.put('buyout/itemupdate/' + data.id, data.value)
  dispatch(setOrderItemDetailsSaveFlag(true))
  dispatch(setLoader(false))
}

export const getReleasePicture = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  let querystring = '?';
  if (data.release_no) {
    querystring = querystring + 'release_no=' + data.release_no
  }
  const response = await clientService.get('buyout/releasepicture'+ querystring)
  dispatch(setLoader(false))
  return response?.data || [];
}

export const releaseOrderItem = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/releaseitem/' + data.id, data.values)
  dispatch(setLatestReleaseNo(response?.data?.data?.releaseNo))
  dispatch(setOrderReleaseSubmitted(response.data))
}

export const releaseItemTempSave = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  await clientService.post('buyout/releaseqty/temp-save',data)
  dispatch(setLoader(false))
}

export const downloadReleasePDF = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/download-release-pdf', data)
  dispatch(setViewPdf(response.data.data))
  window.open(config.pdfUrl + response.data.data)
  dispatch(setLoader(false))
}

export const receivedItemQty = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  await clientService.post('buyout/receiveditem', data)
  dispatch(setOrderRefresh())
  dispatch(getLocation())
}

export const multipleReceivedItemQty = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    await clientService.post('buyout/multiple-receiveditem', data)
    dispatch(setOrderRefresh())
}

export const updateReceivedRowData = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    await clientService.post('buyout/updatereceivedrow', data)
    dispatch(setOrderRefresh())
}

export const getItemDetails = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.get('buyout/itemdetails/' + id)
  dispatch(setOrderItemDetailsSaveFlag(false))
  dispatch(setOrderItemsDetails(response.data));
  dispatch(setLoader(false))
}

export const deleteOrder = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('buyout/order/destroy/' + id)
   dispatch(setOrderDeleteResponse(response.data))

}

export const deleteOrderMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/order/multiple-destroy', data)
  dispatch(setOrderDeleteResponse(response.data))
}

export const deleteReceiveImage = (data) => async (dispatch) => {
  const response = await clientService.put('buyout/deletereceivermage', data)
}

export const closeOrderMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/order/close-order', data)
  dispatch(setOrderCloseResponse(response.data))
}

export const openOrder = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/order/changestatus', data)
  dispatch(setOrderReopenSubmitted({}))
}

export const nextpreorder = (data) => async (dispatch) => {
  const response = await clientService.get('buyout/nextpreorder/' + data.id + "?jobId=" + data.jobId + "&requisite_number=" + data.requisite_number)
  dispatch(setNextPreOrder({
    nextOrderId : response.data.data.nextOrderId,
    preOrderId: response.data.data.preOrderId,
    nextReleaseNo: response.data.data.nextReleaseNo,
  }))
}

export const getOrder = (id) => async (dispatch) => {
    dispatch(setLoader(true))
    dispatch(getCategory())
    const response = await clientService.get('buyout/order/' + id)
    if (response.data.code === 200) {
      let cOrder = response.data.data.data;
      if (cOrder && cOrder.items && cOrder.items.length) {
        let received_quantity = 0;
        cOrder.items.map((obj) => {
          received_quantity = 0;
          if (obj.release_item && obj.release_item.length) {
            let release_quantity = obj.release_item.reduce((b, a) => b + (a.quantity_release ? +a.quantity_release : 0), 0);
            obj['release_quantity'] = release_quantity;
          } else {
            obj['release_quantity'] = 0;
          }
          if (obj.release_item && obj.release_item.length) {
            obj.release_item.map((relObj) => {
              let releaseReceived = 0;
              let imageArr = [];
              if (relObj && relObj.releaseReceived) {
                releaseReceived = relObj.releaseReceived.reduce((b, a) => b + (a.quantity ? +a.quantity : 0), 0);
                relObj['storage_location'] = (relObj.releaseReceived.length) ? relObj.releaseReceived[0].storage_location : '';
                relObj.releaseReceived.map((a) => {
                  imageArr = imageArr.concat(a.mt_order_receive_pics);
                })
                relObj.releaseReceived.sort((a,b) => a.id - b.id);
              }
              relObj['quantity_received'] = releaseReceived;
              relObj['images'] = [...imageArr];
              relObj['dispalyQuantityReceived'] = releaseReceived;
              received_quantity = received_quantity + releaseReceived;
            })
            obj['received_quantity'] = received_quantity;
          }
        })
      }
      await dispatch(getMaterialUOMDropdown({keyword: '', jobId: cOrder.job_id}, () => {}))
      dispatch(setCurrentOrder(cOrder));
      dispatch(nextpreorder({id: id, jobId: cOrder.job_id, requisite_number: cOrder.requisite_number}))
    } else {
      alert('No Record Found')
      dispatch(setLoader(false))
    }
}

export const getTotalBuyoutOrder = (data) => async (dispatch) => {
  if (totalListController) {
    totalListController.abort()
  }
  totalListController = new AbortController();
  dispatch(setLoader(true));
  var flt = {...data }
  if(data && data.departnmentIds){
    flt.departnmentIds =  [...data.departnmentIds];
    // flt.jobId =  [...data.jobId];
    for(var i = 0; i < data.departnmentIds.length; i++){
      if(!data.departnmentIds[i]){
        flt.departnmentIds[i] = 'NULL';
      }
      if(data.departnmentIds[i] == 'all'){
        flt.departnmentIds[i] = -1;
      }
    };
  };
  if (flt.jobId == 'allJo') {
    flt.jobId = -3;
  }
  if (flt.jobId == 'allWo') {
    flt.jobId = -4;
  }
  if (flt.jobId == 'all') {
    flt.jobId = -1;
  }

  const response = await clientService.post('buyout/order/total-order', flt, {signal: totalListController.signal})
  if (response && response.data.code === 200) {
    dispatch(updateStatistics(response.data))
  }
}

export const getEmailDistribution = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post(
    'material-email/email-distribution/',
    data
  )
  dispatch(setEmailDistributionResponse(response.data.data))
}

export const copyEmailFromJob = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material-email/copy',data);
  dispatch(setCopyEmailJobResponse(response.data.data));
  dispatch(getEmailDistribution({job_id : data.toId}));
}

export const getReleaseList = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/orderrelease', data);
  dispatch(setOrderReleaseResponse(response.data.data));
  dispatch(setLoader(false))
}

export const copyMaterialOrder = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('copy-order',data);
  dispatch(setCopyOrderResponse(response.data));
}

export const saveEmails = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('material-email/save-list/', data)
  dispatch(setSaveEmailsResponse(response.data.data))
}

export const deleteEmailDistribution = (id, job_id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('material-email/destroy/' + id);
  dispatch(setEmailDistributionDeleteResponse(response.data));
  dispatch(getEmailDistribution({job_id : job_id}));
}

export const attachment = (data, file) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.pdfUpload(
    'orders/attachment',
    data,
    file
  )
  dispatch(setAttechment(response.data.data.orderAttachments));
  dispatch(setLoader(false))
}

export const removeAttachment = (id) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.remove('order-attachment/' + id);
  dispatch(setAttechment(response.data.data))
  dispatch(setLoader(false))
}

export const getCsvExport = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('buyout/order/csv-export', data)
    if(response?.data) {
        var blob;
        if(response?.data?.code == 400) {
            blob = new Blob(['No Record found'], {type: "text/csv;charset=utf-8"});
        } else {
            blob = new Blob([response.data], {type: "text/csv;charset=utf-8"})
        }
        FileSaver.saveAs(blob, "buyout_orders_csv.csv");
        dispatch(setLoader(false));
    }
}

export const ImportCSVOrder = (data, file) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.fileUpload("buyout/order/csvupload" , data, file);
    dispatch(setLoader(false));
    dispatch(setImportOrderResponse(response.data))
};

export const sendReleaseEmail = (data, file) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.filesUpload("buyout/order/sendmail", data, file);
    dispatch(setEmailSendResponse(response.data))
    dispatch(setLoader(false))
};

export const viewPdf = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('buyout/orders/pdf', data)
  dispatch(setViewPdf(response.data.data))
  window.open(config.pdfUrl + response.data.data)

  dispatch(setLoader(false))
}

export const getLastAttention = (vendorId, orderId) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('last-vendor/', {
    vendor_id : vendorId,
    order_id : orderId
  });
  if(response.data?.data?.lastAttention){
    dispatch(setLastAttention(response.data.data.lastAttention))
  }
  setTimeout(() => {
    dispatch(setLoader(false))
  }, 500)
}


export const {
  updateOrder,
  updateOders,
  updateStatistics,
  filters,
  setOrderDeleteResponse,
  setOrderCloseResponse,
  setEmailDistributionResponse,
  setEmailSendResponse,
  setOrderFormSubmitted,
  setOrderReleaseSubmitted,
  setOrderReleaseInit,
  setOrderRopenInit,
  setOrderRefresh,
  setAfterUpdateFieldInit,
  setOrderReopenSubmitted,
  setOrderItemsDetails,
  setOrderNoteFormSubmitted,
  setCurrentOrder,
  setSaveEmailsResponse,
  resetOrderFormSubmitted,
  setEmailDistributionDeleteResponse,
  setCopyOrderResponse,
  setLoader,
  setNextPreOrder,
  setCopyEmailJobResponse,
  setViewPdf,
  setImportOrderResponse,
  setAttechment,
  setAttachmentDeleteResponse,
  setLastAttention,
  setOrderItemDetailsSaveFlag,
  setOrderReleaseResponse,
  setReleaseReceived,
  setLatestReleaseNo,
  setUpdatePOResponse,
  setUpdateVendorResponse
} = buyoutOrdersSlice.actions

export const buyoutOrdersState = (state) => {
  return state.buyoutOrders
}

export default buyoutOrdersSlice.reducer
