import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

import clientService from '../../services/service'
export const allEmployee = {value: -1, label: 'All Employees'}

export const techCalendarInitialState = {
    loading: false,
    filter: {
        departnmentIds : [],
        job_id : null,
        start_date: '',
        end_date: '',
        employeeIds: [-1]
    },
    tableFilter: {
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
    },
    eventsInActive: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        job_id : null,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    defaultEventColor: '#001f60',
    jobOptionsList: [],
    empOptionsList: [],
    empList: [],
    selectedEmployees: [],
    addEventFormResponse : {},
    deleteResponse: {},
    currentEvent : {},
    eventsList: [],
    techColorList:[],
    allEmpIds: [],
    refetchCalendar: null,
}
let getEmpListController;

export const techCalendarSlice = createSlice({
    name: 'techCalendars',
    initialState: techCalendarInitialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        filters: (state, action) => {
            state.filter = action.payload
            // state.loader = true
        },
        tableFilters: (state, action) => {
            state.tableFilter = action.payload
        },
        setCurrentEvent: (state, action) => {
            state.currentEvent = action.payload
        },
        setAllEmpIds: (state, action) => {
            state.allEmpIds = action.payload
        },
        setEventsList: (state, action) => {
            state.eventsList = action.payload.data
            state.loader = false
        },
        setEventInActiveList: (state, action) => {
            state.eventsInActive = action.payload.data
            state.loader = false
        },
        setTechColorList: (state, action) => {
            state.techColorList = action.payload.data
        },
        setJobOptiosList:(state, action) => {
            state.jobOptionsList = action.payload.data
            state.loader = false
        },
        setEmpOptiosList:(state, action) => {
            state.empOptionsList = action.payload.data
            state.loader = false
        },
        setEmpList:(state, action) => {
            state.empList = action.payload.data
        },
        setSelectedEmployees:(state, action) => {
            state.selectedEmployees = action.payload
        },
        setEventFormSubmitted: (state, action) => {
            state.addEventFormResponse = action.payload;
            state.loader = false;
        },
        setEventDeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setRefetchCalendar: (state, action) => {
            state.refetchCalendar = action.payload;
        },
    },
})

export const getEventList = (filter) => async (dispatch, getState) => {
    dispatch(setLoader(true))
    let filtr = {...filter}
    filtr.employeeIds = filtr.employeeIds.indexOf(-1) > -1 ? getState()?.techCalendars?.allEmpIds : filtr.employeeIds
    const response = await clientService.post('tech-calendar/list', filtr)
    dispatch(setEventsList(response.data.data))
    dispatch(getEmployeeColorList())
}

export const getEventInActiveList = (tableFilter) => async (dispatch) => {
    dispatch(tableFilters(tableFilter))
    dispatch(setLoader(true))
    let data = {...tableFilter}
    const response = await clientService.post('tech-calendar/inactivelist', data)
    dispatch(setEventInActiveList(response.data.data))
}

export const getEmployeeColorList = () => async (dispatch, getState) => {
    const response = await clientService.get('tech-calendar/employeecolor')
    dispatch(setTechColorList(response.data))
}

export const addEvent = (neweventData) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("tech-calendar/create", neweventData);
    dispatch(setEventFormSubmitted(response.data))
};
    
export const editEvent = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("tech-calendar/"+data.id, data.value);
    dispatch(setEventFormSubmitted(response.data))
    // dispatch(setLoader(false));
};

export const deleteEvent = (id, type=0) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("tech-calendar/destroy/"+id+'/'+type);
    dispatch(setEventDeleteResponse(response.data));
};

export const getJobOptionsList = (type) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.get("job/jobOptionsList/"+type);
    dispatch(setJobOptiosList(response.data));
};

export const getEmpOptionsList = () => async (dispatch, getState) => {
    if (getEmpListController) {
        getEmpListController.abort()
      }
      getEmpListController = new AbortController();
    dispatch(setLoader(true))
    const response = await clientService.get("employee/empOptionsList", {signal: getEmpListController.signal});
    let emps = response?.data?.data || [];
    let ids = []
    if (emps && emps?.length) {
        ids = emps.map(a => a.value)
    }
    dispatch(setSelectedEmployees([allEmployee]));
    dispatch(setAllEmpIds(ids));
    emps.unshift(allEmployee)
    dispatch(setEmpOptiosList({data: emps}));
};

export const getEmpList = (departmentId) => async (dispatch, getState) => {
    const response = await clientService.get("employee/empOptionsList?departmentId=" + departmentId);
    let emps = response.data.data;
    dispatch(setEmpList({data: emps}));
};

export const sideMenuCollapse = () => async (dispatch, getState) => {
    dispatch(setRefetchCalendar(new Date().getTime()));
};

export const {
    setLoader,
    filters,
    tableFilters,
    setEventsList,
    setEventInActiveList,
    setTechColorList,
    setCurrentEvent,
    setJobOptiosList,
    setEmpOptiosList,
    setSelectedEmployees,
    setEventFormSubmitted,
    setEventDeleteResponse,
    setAllEmpIds,
    setLoadEmployee,
    setEmpList,
    setRefetchCalendar
} = techCalendarSlice.actions

export const techCalendarState = (state) => {
    return state.techCalendars
}

export default techCalendarSlice.reducer