import { createSlice } from '@reduxjs/toolkit';
import clientService from '../../services/service';
import { setMatOrderRefresh } from './orderSlice';
import { setOrderRefresh } from './buyoutOrderSlice';

export const budgetCategoryInitialState = {
  loading: false,
  selectedCategories:[],
  categories: [],
  assignResponse : {},
  assignPOMaterialsPIDRes: {}
};

export const budgetCategorySlice = createSlice({
  name: 'categories',
  initialState: budgetCategoryInitialState,
  reducers: {
    selectedCategories: (state, action) => {
        state.selectedCategories = action.payload;
    },
    updateCategories: (state, action) => {
        state.categories = action.payload;
    },
    setAssignPIDResponse: (state, action) => {
        state.assignResponse = action.payload;
        state.loader = false;
    },
    setAssignPOMateialsPIDRes: (state, action) => {
        state.assignPOMaterialsPIDRes = action.payload;
        state.loader = false;
    },
    setLoader: (state,action) => {
        state.loader = action.payload
    },
  },
});

export const getCategory = () => async (dispatch) => {
  const response = await clientService.get("budget-category");
  dispatch(updateCategories(response.data.data));
};

export const assignMultiplePID = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("assign-pid" , data);
  dispatch(setAssignPIDResponse(response.data))
  dispatch(setOrderRefresh())
  dispatch(setMatOrderRefresh())
};

export const assignPOMultiplePID = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("assignPOMateialPid" , data);
    dispatch(setAssignPOMateialsPIDRes(response.data))
};
  
export const selectCategoryRedux = (data) => async (dispatch) => {
  dispatch(selectedCategories(data));
};

export const { updateCategories, selectedCategories, setLoader, setAssignPIDResponse, setAssignPOMateialsPIDRes } = budgetCategorySlice.actions;

export const categoriesState = (state) => {
    return state.categories;
};

export default budgetCategorySlice.reducer;
