import { createSlice } from '@reduxjs/toolkit';
import { getDepartmentJobDetails } from 'utils/Helper';
import clientService from '../../services/service';
export const allDepartment = {value: 'all', label: 'All Departments'}

export const departmentInitialState = {
  loading: false,
  selectedDepartments:[],
  selectedDepartmentPto:[allDepartment],
  departments: []
};
let getDepartListController;

export const departmentSlice = createSlice({
  name: 'departments',
  initialState: departmentInitialState,
  reducers: {
    setSelectedDepartments: (state, action) => {
      state.selectedDepartments = action.payload;
    },
    setSelectedDepartmentPto: (state, action) => {
      state.selectedDepartmentPto = action.payload;
    },
    updateDepartments: (state, action) => {
        state.departments = action.payload;
        var hasDipartmentSelected = false;
        let base64ToStringNew = getDepartmentJobDetails();
        var selDept = [];
        if (base64ToStringNew && action.payload && action.payload.length) {
            if (base64ToStringNew.dept_id) {
              const departments = base64ToStringNew.dept_id.toString().split(",");
              if (departments && departments.length > 0) {
                departments.map((obj) => {
                  let selected = null ;
                  if (obj == 'na') {
                    selected = action.payload.find(a => obj == 'na' && a.label == "NA" )
                  } else {
                    selected = action.payload.find(a => a.value == obj)
                  }
                  if (selected) {
                    hasDipartmentSelected = true;
                    selDept.push(selected)
                  }
                })
              }
              // for (var i = 0; i< departments.length; i++) {
              //   for (var j = 0; j< action.payload.length;j++){
              //     if (action.payload[j].value == departments[i]) {
              //       hasDipartmentSelected = true;
              //       selDept.push(action.payload[j])
              //     } else if (departments[i] == 'na' && action.payload[j].label == "NA") {
              //       hasDipartmentSelected = true;
              //       selDept.push(action.payload[j])
              //     }
              //   }
              // }
          }
        }
        if (!hasDipartmentSelected) {
          state.selectedDepartments = [allDepartment];
        } else {
          state.selectedDepartments  = selDept;
        }
    },
  },
});

export const getDepartment = () => async (dispatch) => {
  if (getDepartListController) {
    getDepartListController.abort()
  }
  getDepartListController = new AbortController();
  const response = await clientService.get("department-master", {signal: getDepartListController.signal});
  let dept = response?.data?.data || [];
  dept.unshift(allDepartment)
  dispatch(updateDepartments(dept));
};

export const selectDepartmentRedux = (data) => async (dispatch) => {
  dispatch(setSelectedDepartments(data));
};

export const selectDepartmentPtoRedux = (data) => async (dispatch) => {
  dispatch(setSelectedDepartmentPto(data));
};

export const { updateDepartments, setSelectedDepartments, setSelectedDepartmentPto } = departmentSlice.actions;

export const departmentsState = (state) => {
    return state.departments;
};

export default departmentSlice.reducer;
