import { lazy } from 'react'
const NewOrder = lazy(() => import('pages/order/new'));
const Areas = lazy(() => import('pages/area/list'));
const MaterialDatabase = lazy(() => import('pages/material/list'));
const BTMaterialDatabase = lazy(() => import('pages/btmaterial/list'));
const fieldReporter = lazy(() => import('pages/fieldreporter/list'));
const BudgetSummary = lazy(() => import('pages/fieldreporter/budget-summary'));
const jobAreasList = lazy(() => import('pages/jobarea/list'));
const jobFloorsList = lazy(() => import('pages/jobfloor/list'));
const jobSubsystemsList = lazy(() => import('pages/jobsubsystem/list'));
const POLinesOrders = lazy(() => import('pages/polines/list'));
const POLinesDatabase = lazy(() => import('pages/polines/po-db-list'));
const POLinesSearchList = lazy(() => import('pages/polines/search-list'));
const VendorList = lazy(() => import('pages/vendor/list'));
const ReportPID = lazy(() => import('pages/report/pid'));
const ReportInventory = lazy(() => import('pages/report/inventory'));
const ReportUsage = lazy(() => import('pages/report/usage'));
const Reportreceiving = lazy(() => import('pages/report/receiving'));
const ReportBalanceSheet = lazy(() => import('pages/report/balance-sheet'));
const ReportHoursByPayperiod = lazy(() => import('pages/report/hours-by-payperiod'));
const ReportEmployeeHourly = lazy(() => import('pages/report/employee-hourly'));
const ReportList = lazy(() => import('pages/report/list'));
const SetupList = lazy(() => import('pages/setup/list'));
const Auth = lazy(() => import('pages/auth'));

const Home = lazy(() => import('pages/home'));
const BuyoutDashboard = lazy(() => import('pages/buyout/dashboard'));
const NewBuyoutOrder = lazy(() => import('pages/buyout/new'));
const techEventInActive = lazy(() => import('pages/techcalendar/event-inactive-list'));
const techCalendar = lazy(() => import('pages/techcalendar/list'));
const ptoCalendar = lazy(() => import('pages/ptocalendar/list'));
const PTOAvailable = lazy(() => import('pages/report/ptoavailable'));
const PTORequest = lazy(() => import('pages/report/ptorequest'));
const PTOStatement = lazy(() => import('pages/report/ptostatement'));
const LookBranchSummary = lazy(() => import('pages/report/look-branch-summary'));

const routes = [

  {
    path: '/auth/:token?/:dir?',
    component: Auth,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/order/new',
    component: NewOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/areas-list',
    component: Areas,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/field-reporters',
    component: fieldReporter,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/budget-summary',
    component: BudgetSummary,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/job/areas-list',
    component: jobAreasList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/job/floors-list',
    component: jobFloorsList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/job/fr-breakdown',
    component: jobSubsystemsList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/material-database',
    component: MaterialDatabase,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/bt-material-database',
    component: BTMaterialDatabase,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/polines-orders',
    component: POLinesOrders,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/polines-database',
    component: POLinesDatabase,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/polines-searches',
    component: POLinesSearchList,
    type: 'public',
    authenticated: true,
    app: false,
    exact: true,
  },
  {
    path: '/vendor-list',
    component: VendorList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-pid',
    component: ReportPID,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-inventory',
    component: ReportInventory,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-usage',
    component: ReportUsage,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-receiving',
    component: Reportreceiving,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-balancesheet',
    component: ReportBalanceSheet,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-hours-spent',
    component: ReportHoursByPayperiod,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-employee-hourly',
    component: ReportEmployeeHourly,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/report-list',
    component: ReportList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/setup-list',
    component: SetupList,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/',
    component: Home,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/order/edit/:orderId',
    component: NewOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/order/edit/:orderId/release-received/:orderItemId',
    component: NewOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/buyout',
    component: BuyoutDashboard,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/buyout/new',
    component: NewBuyoutOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/buyout/edit/:orderId',
    component: NewBuyoutOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/buyout/edit/:orderId/release-received/:orderItemId',
    component: NewBuyoutOrder,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/tech-calendar',
    component: techCalendar,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/tech-event-inactive-list',
    component: techEventInActive,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/pto-calendar',
    component: ptoCalendar,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/pto-available',
    component: PTOAvailable,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/pto-request',
    component: PTORequest,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/pto-statement',
    component: PTOStatement,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '/look-branch-summary',
    component: LookBranchSummary,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
  {
    path: '*',
    component: Home,
    type: 'public',
    authenticated: false,
    app: false,
    exact: true,
  },
]

export default routes;
