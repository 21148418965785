import React, { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import AuthRoutes from './AuthRoutes'



export const appRoutes = [
  ...AuthRoutes
]


const getConfirm = (content, callback) => {
  const c = window.confirm(content);
  if(c){
    callback(true);
  }
};
const AppRouter = () => {
  return (
    <BrowserRouter
     getUserConfirmation={getConfirm} >
      <Suspense fallback={<div id="loader"><div className="loader"></div> </div>}>
        <Switch>
          {appRoutes.map((route, i) =>
            (
              <Route
                key={i}
                path={route.path}
                component={route.component}
                exact={route.exact}
                onChange={(e) => {
                }}
              />
            )
          )}
     
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export { AppRouter }
