import { createSlice } from '@reduxjs/toolkit';

import clientService from '../../services/service';

export const vendorInitialState = {
  loading: false,
  filter:{
    page : 1,
    size : 10,
    sort_by: "name",
    sort_order : "asc"
  },
  currentVendor : {},
  selectdVendor:{label : "No Vendor", value : 0},
  firstItem : {label : "No Vendor", value :0},
  importResponse: {},
  vendors : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  vendorsDropdown: [],
  addVendorFormResponse : {},
  deleteResponse: {},
  csvExportData :[],
  vendorLoaded: false
};

export const vendorsSlice = createSlice({
  name: 'vendors',
  initialState: vendorInitialState,
  reducers: {
    filters:(state, action) => {
        state.filter = action.payload;
        state.loader = true;
    },
    updateVendor: (state, action) => {
      state.currentVendor = action.payload;
    },
    updateVendors: (state, action) => {
        state.addVendorFormResponse = {};
        state.vendors = action.payload.data;
        state.loader = false;
    },
    setVendorFormSubmitted: (state, action) => {
        state.addVendorFormResponse = action.payload;
        state.loader = false;
    },
    setVendorDeleteResponse: (state, action) => {
        state.deleteResponse = action.payload;
        state.loader = false;
    },
    setImportVendorResponse: (state, action) => {
      state.importResponse = action.payload;
      state.loader = false;
  },
    setCurrentVendor: (state,action) => {
      state.currentVendor = action.payload
    },
    setLoader: (state,action) => {
      state.loader = action.payload
    },
    setVendorDropDown: (state, action) =>{
      state.vendorsDropdown = [state.firstItem, ...action.payload.data];
      state.selectdVendor = state.firstItem;
    },
    setCsvExportData : (state, action) => {
      state.csvExportData = action.payload.data;
      state.vendorLoaded = true;
      state.loader = false;
    }
  },
});

export const getVendors = (filter) => async (dispatch) => {
  dispatch(filters(filter));
  dispatch(setLoader(true));
  dispatch(setCurrentVendor({}));
  const response = await clientService.post("vendor/list", filter);
  dispatch(updateVendors(response.data.data));
};

export const addVendors = (newVendorData) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("vendor", newVendorData);
  dispatch(setVendorFormSubmitted(response.data))
};


export const editVendors = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.put("vendor/" + data.id, data.value);
  dispatch(setVendorFormSubmitted(response.data))
  dispatch(setLoader(false));
};

export const deleteVendor = (id) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.remove("vendor/destroy/" + id);
  dispatch(setVendorDeleteResponse(response.data));
};
export const deleteVendorMultiple = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("vendor/multiple-destroy" , data);
  dispatch(setVendorDeleteResponse(response.data))
};


export const importVendorList = (data, file) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.fileUpload("vendor/upload" , data, file);
  dispatch(setLoader(false));
  dispatch(setImportVendorResponse(response.data))
};

export const vendorList = () => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.get("vendor/");
  dispatch(setCsvExportData(response.data.data));
};

export const getVendorDropdownList = (data) => async (dispatch) => {
  const response = await clientService.post("vendor/dropdwon", data);
  dispatch(setVendorDropDown(response.data.data));
};

export const {
  updateVendor,
  updateVendors,
  filters,
  setVendorDeleteResponse,
  setVendorFormSubmitted,
  setCurrentVendor,
  setCsvExportData,
  setVendorDropDown,
  setImportVendorResponse,
  setLoader } = vendorsSlice.actions;

export const vendorsState = (state) => {
    return state.vendors;
};

export default vendorsSlice.reducer;
