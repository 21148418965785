import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';

import { combineReducers } from 'redux';
import throttle from 'lodash/throttle';
import usersReducer from './slices/usersSlice';
import ordersReducer from './slices/orderSlice';
import areasReducer from './slices/areasSlice';
import buyoutOrdersReducer from './slices/buyoutOrderSlice';
import departmentsReducer from './slices/departmentSlice';
import fieldReportersReducer from './slices/fieldReporterSlice';
import budgetSummaryReducer from './slices/budgetSummarySlice';
import jobsReducer from './slices/jobSlice';
import jobAreasReducer from './slices/jobAreasSlice';
import jobFloorsReducer from './slices/jobFloorsSlice';
import jobSubsystemsReducer from './slices/jobSubsystemsSlice';
import vendorReducer from './slices/vendorsSlice';
import materialReducer from './slices/materialSlice';
import categoryReducer from './slices/budgetCategorySlice';
import costCodeReducer from './slices/costCodeSlice';
import attentionReducer from './slices/attentionSlice';
import reportReducer from './slices/reportsSlice';
import poLinesReducer from './slices/poLinesSlice';
import locationReducer from './slices/locationSlice';
import techCalendarReducer from './slices/techCalendarSlice';
import ptoCalendarReducer from './slices/ptoCalendarSlice';
import employeeReducer from './slices/employeeSlice';

const rootReducer = combineReducers({
  users: usersReducer,
  orders: ordersReducer,
  areas: areasReducer,
  buyoutOrders: buyoutOrdersReducer,
  departments: departmentsReducer,
  fieldReporters: fieldReportersReducer,
  budgetSummary: budgetSummaryReducer,
  jobs: jobsReducer,
  jobAreas: jobAreasReducer,
  jobFloors: jobFloorsReducer,
  jobSubsystems: jobSubsystemsReducer,
  vendors: vendorReducer,
  materials : materialReducer,
  categories : categoryReducer,
  costCodes : costCodeReducer,
  attentions : attentionReducer,
  reports : reportReducer,
  polines : poLinesReducer,
  locations: locationReducer,
  techCalendars : techCalendarReducer,
  ptoCalendars : ptoCalendarReducer,
  employees:employeeReducer
});

const preloadedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(
  throttle(() => {
    saveState({
      users: store.getState().users,
      orders: store.getState().orders,
      areas: store.getState().areas,
      buyoutOrders:store.getState().buyoutOrders,
      departments: store.getState().departments,
      fieldReporters: store.getState().fieldReporters,
      budgetSummary: store.getState().budgetSummary,
      jobs: store.getState().jobs,
      jobAreas: store.getState().jobAreas,
      jobFloors: store.getState().jobFloors,
      jobSubsystems: store.getState().jobSubsystems,
      vendors: store.getState().vendors,
      materials : store.getState().materials,
      categories : store.getState().categories,
      costCodes : store.getState().costCodes,
      attentions : store.getState().attentions,
      reports : store.getState().reports,
      polines : store.getState().polines,
      locations: store.getState().locations,
      techCalendars : store.getState().techCalendars,
      ptoCalendars : store.getState().ptoCalendars,
      employees : store.getState().employees
    });
  }, 3000)
);

export default store;
