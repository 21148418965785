import { createSlice } from '@reduxjs/toolkit'
import { setLocalStore } from 'utils/Helper'
import clientService from '../../services/service'
let obortApiController, geUserInfoController;

const usersInitialState = {
  user : {
    name : ""
  },
  userInfo : [],
  divisionInfo: [],
  isAdminUser: false,
  permission : {
    materialarea:{view_perms : 1},
    floor:{view_perms : 1},
    fieldreporter:{view_perms : 1},
    area:{view_perms : 1},
    subsystem:{view_perms : 1},
    material : {view_perms : 1},
    database:{view_perms : 1},
    vendor:{view_perms : 1},
    buyout:{view_perms : 1},
    polinesdb:{view_perms : 1},
    techcalendar:{view_perms : 1},
    ptocalendar:{view_perms : 1},
    report :{
      view_perms : 1,
    },
    lookbranchsummary: {view_perms : 1},
    btdatabase: {view_perms : 1}
  },
  moduleId : {
    76 : 'material',
    75 : 'database',
    79 : 'vendor',
    114: 'ptocalendar',
    132: 'buyout',
    146: 'polinesdb',
    156: 'materialarea',
    157: 'lookbranchsummary',
    158: 'floor',
    159: 'area',
    160: 'subsystem',
    161: 'fieldreporter',

    77 : 'material',
    78 : 'database',
    84 : 'vendor',
    104: 'ptocalendar',
    135: 'buyout',
    140: 'techcalendar',
    142: 'btdatabase'
  },
  menuList: {},
  userPermList: {},
  adminVideoInfo: [],
  allMenu: [],
  pageTitle: '',
  isSideMenuCollapse: 1,
  isStateCollapse: null,
  notyCount: 0,
  notyData: {},
  sitebrandLogo: {},
}

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    updateUser: (state, action) => {
      state.current = action.payload
    },
    updateUserInfo: (state, action) => {
      state.userInfo  = action.payload
      state.user.name = action.payload.userInfo.name;
      let userRole    = state?.userInfo?.userInfo?.role_id;
      let permissionObj = {}
      for(var i =0; i < action.payload.userPermission.length; i++){
          permissionObj[state.moduleId[action.payload.userPermission[i]['menu_item_id']]] = action.payload.userPermission[i]
      }
      if (!permissionObj?.ptocalendar?.id) {
        if(userRole == 2 || userRole == 3) {
            permissionObj.ptocalendar = { view_perms : 1, edit_perms: 1}
        } else {
            permissionObj.ptocalendar = { view_perms : 0}
        }
      }
      state.permission  = permissionObj;
      if(userRole == 2 || userRole == 3) {
        state.isAdminUser = true;
      }
      if(action.payload.menuPermission.length){
        state.menuList = action.payload.menuPermission
      }
      if(action.payload.userPermission.length){
        state.userPermList = action.payload.userPermission
      }
      state.isSideMenuCollapse = action.payload?.userSideMenuSetting?.isSideMenuCollapse ? parseInt(action.payload?.userSideMenuSetting?.isSideMenuCollapse) : 1
      state.isStateCollapse    = action.payload?.userStateCollapse?.isStateCollapse ? parseInt(action.payload?.userStateCollapse?.isStateCollapse) : 1
      state.notyCount = action.payload.cmpNotificationCount
      state.notyData = action.payload.cmpNotificationData
    },
    updateAdminVideo: (state, action) => {
        state.adminVideoInfo = action.payload
    },
    updateAllMenu: (state, action) => {
        state.allMenu = action.payload
    },
    updatePageTitle: (state, action) => {
        state.pageTitle = action.payload
    },
    setDivisionInfo: (state, action) => {
		setLocalStore('divisionInfo', action.payload)
        state.divisionInfo = action.payload
    },
    setSideMenuColleapse: (state, action) => {
        state.isSideMenuCollapse = action.payload
    },
    setStateFilterColleapse: (state, action) => {
        state.isStateCollapse = action.payload
    },
    setSiteBrandLogo: (state, action) => {
      state.sitebrandLogo = action.payload
    }
  },
})

export const updateNotificationStatus = (data) => async (dispatch) => {
  await clientService.put("user/updateNotification", data);
  dispatch(getUserInfo());
};

export const saveNewUser = (newUserData) => async (dispatch) => {
  const response = await clientService.post('/user', newUserData)
  dispatch(updateUser(response.data.createUser))
}

export const getUserInfo = () => async (dispatch) => {
  if (geUserInfoController) {
    geUserInfoController.abort()
  }
  geUserInfoController = new AbortController();
  const response = await clientService.get("user-info", {signal: geUserInfoController.signal});
  if (response?.data?.data) {
    dispatch(updateUserInfo(response?.data?.data));
  }
};

export const updateUserDetails = (data) => async (dispatch) => {
  await clientService.put("user/update", data);
  dispatch(getUserInfo());
};

export const updateUserCollapseSettings = (data) => async (dispatch) => {
    await clientService.post("user/sidemenu-collapse", data);
};

export const updateReduxAllMenu = (data) => async (dispatch) => {
  dispatch(updateAllMenu(data));
};

export const updateReduxPageTitle = (data) => async (dispatch) => {
  dispatch(updatePageTitle(data));
};

export const getAdminVideos = (id) => async (dispatch) => {
  const response = await clientService.get('admin-video/'+id);
  dispatch(updateAdminVideo(response.data.data));
}

export const getDivisionBrandLogo = (data) => async (dispatch) => {
  const response = await clientService.head(data);
  dispatch(setSiteBrandLogo(response));
}

export const getDivisionPayPeriod = () => async (dispatch) => {
    if (obortApiController) {
        obortApiController.abort()
    }
    obortApiController = new AbortController();
    const response = await clientService.get("get-payperiod", {signal:  obortApiController.signal});
    dispatch(setDivisionInfo(response?.data?.data || []));
};

export const { updateUser, updateUserInfo, updateAdminVideo, setDivisionInfo, setSideMenuColleapse, setStateFilterColleapse, updateAllMenu, updatePageTitle, setSiteBrandLogo } = usersSlice.actions
export const usersState = (state) => state.users
export default usersSlice.reducer
