import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'
var FileSaver = require('file-saver');

export const fieldReporterInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
        filter_budget: '',
        filter_floor: '',
        filter_area: '',
        filter_ss: '',
        filter_publish_date: '',
        filter_start_date: '',
        filter_end_date: '',
        publishedId: 0
    },
    reportings: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    publishResponse: {},
    publishLogRecords: {},
    deleteResponse:{},
    updateResponse:{},
    addFRResponse:{},
}

export const fieldReporterSlice = createSlice({
    name: 'fieldReporters',
    initialState: fieldReporterInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setReportings: (state, action) => {
            state.reportings = action?.payload?.data
            state.loader = false
        },
        setPublishResponse: (state, action) => {
            state.publishResponse = action?.payload
            state.loader = false
        },
        setPublishLogRecords: (state, action) => {
            state.publishLogRecords = action?.payload?.data
            state.loader = false
        },
        setDeleteFieldDataResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setaddFieldDataResponse: (state, action) => {
            state.addFRResponse = action.payload;
            state.loader = false;
        },
        setUpdateFieldDataResponse: (state, action) => {
            state.updateResponse = action.payload;
            state.loader = false;
        }
    },
})

export const getReportingList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('fieldreporters/list', data)
    dispatch(setReportings(response?.data?.data))
}

export const getCsvExport = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('fieldreporters/csv-export', data)
    if(response?.data) {
        var blob;
        if(response?.data?.code == 400) {
            blob = new Blob(['No Record found'], {type: "text/csv;charset=utf-8"});
        } else {
            blob = new Blob([response.data], {type: "text/csv;charset=utf-8"})
        }
        FileSaver.saveAs(blob, "field_reporter_csv.csv");
        dispatch(setLoader(false));
    }
}

export const publishReport = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('fieldreporters/publish', data)
    dispatch(setPublishResponse(response?.data))
}

export const getPublishRecords = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('fieldreporters/getpublish', data)
    dispatch(setPublishLogRecords(response?.data?.data))
}

export const deleteFieldData = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("fieldreporters/deleteFieldData", data);
    dispatch(setDeleteFieldDataResponse(response.data))
};

export const updateFieldData = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("fieldreporters/updateFieldData", data);
    dispatch(setUpdateFieldDataResponse(response.data))
};

export const addFieldReporteData = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("fieldreporters/addFieldReporteData", data);
    dispatch(setaddFieldDataResponse(response.data))
};

export const {
    filters,
    setLoader,
    setReportings,
    setPublishResponse,
    setPublishLogRecords,
    setDeleteFieldDataResponse,
    setaddFieldDataResponse,
	  setUpdateFieldDataResponse
} = fieldReporterSlice.actions

export const fieldReporterState = (state) => {
    return state.fieldReporters
}

export default fieldReporterSlice.reducer
