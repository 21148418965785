export const getParams = (params) => {
    let paramsStr = '?';
    if (params && typeof params === 'object' && Object.keys(params).length) {
        Object.keys(params).map((obj, index) => {
            if (index === 0) {
                paramsStr = paramsStr + obj + "=" + params[obj]
            } else {
                paramsStr = paramsStr + "&" + obj + "=" + params[obj]
            }
        })
    }
    return paramsStr
}

export const getHyphnesVal = (val) => {
    return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
}