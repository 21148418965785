import moment from 'moment';
import config from '../config';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
window.Buffer = window.Buffer || require("buffer").Buffer;

const domain = config.siteDomain;
export const allOption = ['all', 'allJo', 'allWo']

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
}

export const getFirstJob = (jobList) => {
    return (isNumeric(jobList[0]?.value)) ? jobList[0] :
    (isNumeric(jobList[1]?.value)) ? jobList[1] : (isNumeric(jobList[2]?.value)) ? jobList[2] :  (isNumeric(jobList[3]?.value)) ? jobList[3] : jobList[4]
}

export const DateTimeFormat = (serverTime, format, timeZone = 0) => {

    const dateFormate = format ? format : "MM/DD/YYYY hh:mm A";
    if(!serverTime){
        return ""
    }
    let  date = moment(serverTime);
    if(!date.isValid()){
        return "";
    }
    if (timeZone) {
        let dtmArr = serverTime.split('.');
        if (dtmArr && dtmArr.length) {
            serverTime = moment(dtmArr[0]).format(dateFormate);
        }
    }
    return serverTime;
}

export const getCurrentDateTime = (format) => {
  const dateFormate = format ? format : "MM/DD/YYYY hh:mm A";
  return moment().format(dateFormate);
}

export const getTokenDetails = () => {
    let token = localStorage.getItem('token');
    if (token) {
        let buff = Buffer.from(token, 'base64');
        let base64ToStringNew = buff.toString('ascii');
        if (base64ToStringNew) {
            return JSON.parse(base64ToStringNew);
        }
    }
    return null
}

export const getDepartmentJobDetails = () => {
    let token = localStorage.getItem('token');
    if (token) {
        let buff = Buffer.from(token, 'base64');
        let base64ToStringNew = buff.toString('ascii');
        let data = {};
        if (base64ToStringNew) {
            data = JSON.parse(base64ToStringNew);
        }
        let job_id = Cookies.get(config.jobIdKey, { domain: domain })
        let dept_id = Cookies.get(config.deptIdKey, { domain: domain })
        if (job_id !== undefined) {
            data['job_id'] = job_id
        }
        if (dept_id !== undefined) {
            data['dept_id'] = dept_id
        }
        return data
    }
    return null
}

export const setCookies = (key, value) => {
    Cookies.set(key, value, { domain: domain, secure: false })
}

export const getCookies = (name) => {
  const cookieValues = Cookies.get(name, { domain: domain });
  return (cookieValues !== null && cookieValues !== undefined ? cookieValues : null);
}

export const removedivElement = (className) => {
    let list = document.getElementsByClassName(className);
    for (let i = list.length - 1; 0 <= i; i--)
    if (list[i] && list[i].parentElement)
    list[i].parentElement.removeChild(list[i]);
}

export const isEmpty = (value) => {
    let key, i, len;
    let emptyValues = ["undefined", 'null', null, false, 0, '', '0', undefined];
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (value === emptyValues[i]) {
            return true;
        }
    }
    if (typeof value === 'object') {
        for (key in value) {
            return false;
        }
        return true;
    }
    return false;
}

export const setLocalStore = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStore = (key) => {
    const lvalue = localStorage.getItem(key)
    if (lvalue) {
        return JSON.parse(lvalue)
    }
    return lvalue
}

export const getConvertedDate = (dateTime) => {
    if (!dateTime) {
        return dateTime;
    }
    var dateObj = dateTime.split('T');
    var createdDate = dateObj[0].split('-');
    var createdTime = dateObj[1].split(':');
    var year  = createdDate[0];
    var month = createdDate[1];
    var day   = createdDate[2];
    var time  = createdTime[0]  + ':'+createdTime[1];
    return month+'/'+day+'/'+year+' '+time;
}

export const getPageMenu = (allMenu, parentType, permission, userInfo, subMenuType) => {
    let showAllSetupList;
    let showAllReportList;
    let menuItemsList;
    if (displaySubmenuinSearch(allMenu, subMenuType, parentType)) {
        let listMenuParent = [];
        let uniqueMenuIds = [];
        menuItemsList = (
            allMenu.map((item, index) => {
                listMenuParent = item.target_menu.split(",");
                if (!listMenuParent.includes(parentType) || item.menu_type !== subMenuType || (subMenuType === 'setup' && parentType === 'manpower' && item.menu_item_title === 'Employee List')) {
                    return null
                } else {
                    if (!uniqueMenuIds.includes(item?.menu_id)) {
                        uniqueMenuIds.push(item?.menu_id)
                        return <li key={'submenupage'+index}><a className="font-size14" href={item.menu_file_name}>{item.menu_item_title}</a></li>
                    }
                }
            })
        );
    }

    if (subMenuType === 'setup') {
        showAllSetupList = <li><Link className="font-size14" target="_blank" to="/setup-list"><span className="sr-page-link">Show all setups</span></Link></li>
    }

    if (subMenuType === 'report') {
        showAllReportList = <li><Link className="font-size14" target="_blank" to="/report-list"><span className="sr-page-link">Show all reports</span></Link></li>
    }

    return [menuItemsList, showAllSetupList, showAllReportList];
}

export const checkSubMenuExist = (allMenu, parentType, subMenuType) => {
    return allMenu.some(element => element?.parent_name === parentType && element?.menu_type === subMenuType);
}

export const displaySubmenuinSearch = (allMenu, menuType, parentType) => {
    return allMenu.some(element => {
      if (element.menu_type === menuType && element.parent_name === parentType) {
        return true;
      }
      return false;
    });
}

export const getActualPageLink = (slug_name) => {
    if (slug_name !== "" && slug_name.includes('.php')) {
        return config.siteUrl + 'company/'+slug_name
    } else {
        return '/'+slug_name;
    }
}

export const formatAsPercent = (num) => {
  return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  }).format(num / 100);
}

export const getSiteLogo = () => {
  let getSiteLogo;
  if(config.isSaaSVersion !== 'true') {
		getSiteLogo = config.siteUrl+'panel/images/menu_logo_v1.jpg';
	} else {
    getSiteLogo = config.siteUrl+'panel/images/onsite-logo.png';
	}
  return getSiteLogo;
}

export const getPagesLogo = () => {
  let getSiteLogo;
  if(config.isSaaSVersion !== 'true') {
		getSiteLogo = config.siteUrl+'panel/images/fsg_logo.png';
	} else {
		getSiteLogo = config.siteUrl+'panel/images/onsite-logo.png';
	}
  return getSiteLogo;
}
