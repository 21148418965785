import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'
import { saveAs } from 'file-saver';
var FileSaver = require('file-saver');

export const polinesInitialState = {
    loading: false,
    filter: {
        job_id : null,
        filter_vendor: '',
        filter_category: '',
        filter_ponum: '',
        keyword: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'asc'
    },
    importResponse: {},
    poOrders: {
        totalPages: 1,
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 2,
        to: 20,
        total: 20,
        data: [],
    },
    dbFilter: {
        keyword: '',
        page: 1,
        size: 100,
        sort_by: '',
        sort_order: 'asc'
    },
    poDatabase: {
        totalPages: 1,
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 2,
        to: 20,
        total: 20,
        data: [],
    },
    ssFilter: {
        keyword: '',
        page: 1,
        size: 100,
        sort_by: '',
        sort_order: 'asc'
    },
    ssList: {
        totalPages: 1,
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 2,
        to: 20,
        total: 20,
        data: [],
    },
    ssDeleteResponse: {},
    poVendorsDropdown: [],
    poNumberDropdown: [],
    addSearchResponse: {},
    editSearchResponse: {},
    savedSearchList: {},
    ssCsvExportData:[],
    ssImportResponse:{},
    importPOLinesCsvRes:{}
}

export const poLinesSlice = createSlice({
    name: 'polines',
    initialState: polinesInitialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        dbFilters: (state, action) => {
            state.dbFilter = action.payload
            state.loader = true
        },
        ssFilters: (state, action) => {
            state.ssFilter = action.payload
            state.loader = true
        },
        updatePoLinesData: (state, action) => {
            state.poOrders =  action.payload.data
        },
        updatePoLinesDatabase: (state, action) => {
            state.poDatabase =  action.payload.data
        },
        updateSaveSearchList: (state, action) => {
            state.ssList =  action.payload.data
        },
        setImportPoLinesResponse: (state, action) => {
            state.importResponse = action.payload;
            state.loader = false;
        },
        setAddSearchResponse: (state, action) => {
            state.addSearchResponse = action.payload;
            state.loader = false;
        },
        setEditSearchResponse: (state, action) => {
            state.editSearchResponse = action.payload;
            state.loader = false;
        },
        setPOVendorDropDown: (state, action) =>{
            state.poVendorsDropdown = action.payload.data;
        },
        setSavedSearchList: (state, action) =>{
            state.savedSearchList = action.payload.data;
        },
        setSaveSearchDeleteResponse: (state, action) => {
            state.ssDeleteResponse = action.payload;
            state.loader = false;
        },
        setSsCsvExportData: (state, action) => {
            state.ssCsvExportData = action?.payload?.data;
            state.loader = false;
        },
        setSsImportResponse: (state, action) => {
            state.ssImportResponse = action.payload;
            state.loader = false;
        },
        setPONumberDropdown: (state, action) => {
            state.poNumberDropdown = action.payload.data;
            state.loader = false;
        },
        setImportPOLinesCsvRes: (state, action) => {
            state.importPOLinesCsvRes = action.payload;
            state.loader = false;
        },
    },
})

export const getPoLinesRecords = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    let data = {...filter}
    const response = await clientService.post('polines/list', data)
    dispatch(updatePoLinesData(response.data.data))
    dispatch(setLoader(false))
}

export const getPoLinesDatabase = (filter) => async (dispatch) => {
    dispatch(dbFilters(filter))
    let data = {...filter}
    const response = await clientService.post('polines/database/list', data)
    dispatch(updatePoLinesDatabase(response.data.data))
    dispatch(setLoader(false))
}

export const getSaveSearchList = (filter) => async (dispatch) => {
    dispatch(ssFilters(filter))
    let data = {...filter}
    const response = await clientService.post('polines/save-search/list', data)
    dispatch(updateSaveSearchList(response.data.data))
    dispatch(setLoader(false))
}

export const addSearchesRecords = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('polines/searchesadd', data)
    dispatch(setAddSearchResponse(response?.data))
    dispatch(setLoader(false))
}

export const editSearchesRecords = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('polines/searchesedit', data)
    dispatch(setEditSearchResponse(response?.data))
    dispatch(setLoader(false))
}

export const importPoLines = (data, file) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('polines/import', data, {timeout: 150000})
    dispatch(setImportPoLinesResponse(response?.data))
}

export const getPOVendorDropdownList = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post("polines/vendor-dropdown", data);
    dispatch(setPOVendorDropDown(response.data.data));
    dispatch(setLoader(false))
};

export const getPONumberList = (id) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.get("polines/ponumber-dropdown/"+id);
    dispatch(setPONumberDropdown(response.data.data));
    dispatch(setLoader(false))
}

export const getSearchDropdownList = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.get(`polines/getsearcheslist?name=${data}`);
    dispatch(setSavedSearchList(response?.data));
    dispatch(setLoader(false))
};

export const deletePOSearch = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("polines/search/destroy/"+id);
    dispatch(setSaveSearchDeleteResponse(response.data));
};

export const deletePOSearchMultiple = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("polines/search/multiple-destroy/", data);
    dispatch(setSaveSearchDeleteResponse(response.data));
};

export const csvExportList = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.get("polines/search/export/");
    if(response?.data) {
        var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "saved_searches_csv.csv");
    }
    dispatch(setLoader(false));
};

export const importSavedSearches = (data, file) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.fileUpload('polines/search/import', data, file)
    dispatch(setLoader(false))
    dispatch(setSsImportResponse(response?.data))
};

export const importCsvPOLines = (data, file) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.fileUpload('polines/importcsvpolines', data, file)
    dispatch(setLoader(false))
    dispatch(setImportPOLinesCsvRes(response?.data))
};

export const csvPODbExport = (jobId) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.get("polines/ordersExport/"+jobId);
    if(response?.data) {
        var blob;
        if(response?.data?.code == 400) {
            blob = new Blob(['No Record found'], {type: "text/csv;charset=utf-8"});
        } else {
            blob = new Blob([response.data], {type: "text/csv;charset=utf-8"})
        }
        FileSaver.saveAs(blob, "po_db_csv.csv");
    }
    dispatch(setLoader(false));
};

export const {
  filters,
  dbFilters,
  ssFilters,
  updatePoLinesData,
  updatePoLinesDatabase,
  updateSaveSearchList,
  setImportPoLinesResponse,
  setAddSearchResponse,
  setEditSearchResponse,
  setLoader,
  setPOVendorDropDown,
  setPONumberDropdown,
  setSavedSearchList,
  setSaveSearchDeleteResponse,
  setSsCsvExportData,
  setSsImportResponse,
  setImportPOLinesCsvRes
} = poLinesSlice.actions

export const polinesState = (state) => {
    return state.polines
}

export default poLinesSlice.reducer
