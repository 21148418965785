import { createSlice } from '@reduxjs/toolkit';
import { allOption, getDepartmentJobDetails, setCookies } from 'utils/Helper';
import clientService from '../../services/service';
import config from 'config';
export const allJobOption = {label : "All Jobs / WO Selected", value : 'all'};
const allWOOption = {label : "All WO Selected", value : -1};
const onlyJobsOption = {label : "All Jobs Only", value : 'allJo'};
const onlyWOOption = {label : "All Work Orders Only", value : 'allWo'};
const noWOOption = {label : "No Work-Order", value : -2};
const noJobOption = {label : "No Job / WO", value : -2};
let getJobListHeaderController, getJobListController, getJobListWOController, getPtoJobListController;
// window.location.pathname.match('/tech-calendar')

export const jobInitialState = {
  loading: false,
  filter: {},
  selectdJobs: allJobOption,
  selectedJobPto: allJobOption,
  selectedWo: allWOOption,
  jobs: [],
  woLists: [],
  ptoJobLists: [],
  requisiteNumber: {
    data : {}
  },
  firstItem : allJobOption,
  firstWo : allWOOption,
  copyJobsList : [],
  oldOrderData: {},
  previousUrl: '',
  createdJob: null,
  createdWoDetails: null,
  existJobResponse: null,
  selectFirstJob: false
};

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: jobInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload
    },
    updateFilter: (state, action) => {
      state.filter = action.payload;
    },
    selectedJobs: (state, action) => {
      if (state?.selectdJobs?.value != action?.payload?.value){
        state.selectdJobs = action.payload;
      }
    },
    setSelectedJobPto: (state, action) => {
      if (state?.selectedJobPto?.value != action?.payload?.value){
        state.selectedJobPto = action.payload;
      }
    },
    setSelectedWO: (state, action) => {
      if (state.selectedWo.value != action?.payload?.value){
        state.selectedWo = action.payload;
      }
    },
    updateJobs: (state, action) => {
       state.topMenujobs = [state.firstItem,state.secondItem,state.thirdItem, ...action.payload];
       state.jobs = action.payload;
    },
    setCreatedJob: (state, action) => {
       state.createdJob = action.payload;
       let wolist = [...state.woLists];
       wolist.push({...action.payload})
       state.woLists = [...wolist];

       let ptolist = [...state.ptoJobLists];
       ptolist.push({...action.payload});
       state.ptoJobLists = [...ptolist];

       state.loading = false
    },
    setExistJob: (state, action) => {
       state.existJobResponse = action.payload;
       state.loading = false
    },
    setJobCreatedDetails: (state, action) => {
       state.createdWoDetails = action.payload;
    },
    updateWo: (state, action) => {
       state.woLists = [...action.payload];
    },
    updatePtoJob: (state, action) => {
      state.ptoJobLists = [...action.payload];
   },
    setPreviousUrl: (state, action) => {
       state.previousUrl = action.payload;
    },
    setSelectFirstJob: (state, action) => {
       state.selectFirstJob = action.payload;
    },
    copyFromJobs: (state, action) => {
      state.copyJobsList = action.payload;
   },
    updateNewJobs: (state, action) => {
      state.topMenujobs = [...action.payload];
      state.jobs = action.payload;
      var hasDipartmentSelected = false;
      let base64ToStringNew = getDepartmentJobDetails();
      var selDept = {};
      if (base64ToStringNew) {
        if (base64ToStringNew.job_id) {
          if (action.payload && action.payload.length) {
            let jobSelected = action.payload.find(a => a.value == base64ToStringNew.job_id);
            if (jobSelected) {
              hasDipartmentSelected = true;
              selDept = jobSelected;
            }
          }
        }
      }
      if(!hasDipartmentSelected){
        state.selectdJobs =state.firstItem;
      }else{
        state.selectdJobs = selDept;
      }

    },
    setRequestNumber :(state, action) => {
      state.requisiteNumber = action.payload;
      // state.oldOrderData = action.payload.orderDetail;
    }
  },
});

export const getJobs = (filter) => async (dispatch) => {
  if (getJobListController) {
    getJobListController.abort()
  }
  getJobListController = new AbortController();
  dispatch(updateFilter(filter));
  const response = await clientService.post("job", filter, {signal: getJobListController.signal});
  dispatch(updateJobs(response.data.data));
};

export const copyFromJobList = (data) => async (dispatch) => {
  const response = await clientService.post("email-copy/list", data);
  dispatch(copyFromJobs(response.data.data));
};
export const getHeaderJobs = (filter) => async (dispatch, getState) => {
  if (getJobListHeaderController) {
    getJobListHeaderController.abort()
  }
  getJobListHeaderController = new AbortController();
  let tokenDetail = getDepartmentJobDetails()
  const selectFirstJob = getState()?.jobs?.selectFirstJob;
  dispatch(updateFilter(filter));
  const type = 0;
  let data = {...filter, type}
  let department_id = [...data.department_id];
  for(var i = 0; i < data.department_id.length; i++){
    if (department_id[i] == 'all') {
      department_id[i] = -1;
    }
  };
  data.department_id = department_id;
  const response = await clientService.post("job", data, {signal: getJobListHeaderController.signal});
  let firstItem = {}, selectdJobs;
  let joblist = response?.data?.data || [];
  if (joblist && joblist?.length > 0) {
    firstItem = {...allJobOption};
    if (allOption.includes(tokenDetail?.job_id)) {
      if (tokenDetail?.job_id == 'all') {
        selectdJobs = allJobOption;
      } else if (tokenDetail?.job_id == 'allJo') {
        selectdJobs = onlyJobsOption;
      } else if (tokenDetail?.job_id == 'allWo') {
        selectdJobs = onlyWOOption;
      }
    } else {
      // selectdJobs = joblist[0];
      selectdJobs = joblist.find(a => a.value == tokenDetail?.job_id)
    }
    if (selectFirstJob) {
      selectdJobs = joblist[0];
      setCookies(config.jobIdKey, selectdJobs?.value)
    }
    joblist.unshift({...onlyWOOption})
    joblist.unshift({...onlyJobsOption})
    joblist.unshift(firstItem)
  } else {
    selectdJobs = {...noJobOption};
    firstItem = {...noJobOption};
  }

  dispatch(updateNewJobs(joblist));
  dispatch(selectedJobs(selectdJobs))
  await dispatch(setSelectFirstJob(false))
};

export const getHeaderWo = (filter) => async (dispatch) => {
  if (getJobListWOController) {
    getJobListWOController.abort()
  }
  getJobListWOController = new AbortController();
  dispatch(updateFilter(filter));
  const type = 1;
  let data = {...filter, type}
  let department_id = [...data.department_id];
  for(var i = 0; i < data.department_id.length; i++){
    if (department_id[i] == 'all') {
      department_id[i] = -1;
    }
  };
  data.department_id = department_id;
  const response = await clientService.post("job", data, {signal: getJobListWOController.signal});
  let firstItem = {}, selectdJobs;
  let joblist = response?.data?.data;
  if (joblist && joblist?.length > 0) {
    firstItem = {...allWOOption};
    selectdJobs = firstItem;
    joblist.unshift(selectdJobs)
  } else {
    selectdJobs = {...noWOOption};
    firstItem = {...noWOOption};
  }
  dispatch(updateWo(response?.data?.data || []));
  dispatch(setSelectedWO(selectdJobs))
};

export const selectJobsRedux = (data) => async (dispatch) => {
  dispatch(selectedJobs(data));
};

export const createJob = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  dispatch(setJobCreatedDetails({
    employee: data.techuser,
    location: `${data.site_address} ${data.address2} ${data.city} ${data.state} ${data.zip}`
  }));
  const response = await clientService.post("job/create", data);
  if (response.data.code == 200) {
    dispatch(setCreatedJob(response.data.data));
  } else {
    dispatch(setExistJob(response.data));
  }
};

export const selectWosRedux = (data) => async (dispatch) => {
  dispatch(setSelectedWO(data));
};

export const selecJobPtoRedux = (data) => async (dispatch) => {
  dispatch(setSelectedJobPto(data));
};

export const getRequisiteNumber = (jobId, buyout=0) => async (dispatch) => {
  const response = await clientService.get("requisite-number/" + jobId + "?buyout="+buyout);
  dispatch(setRequestNumber(response.data));
};

export const getPtoJobList = (filter) => async (dispatch) => {
  if (getPtoJobListController) {
    getPtoJobListController.abort()
  }
  getPtoJobListController = new AbortController();
  dispatch(updateFilter(filter));
  const response = await clientService.post("ptoJobList", {signal: getPtoJobListController.signal});
  let joblist = response?.data?.data || [];
  joblist.unshift({...onlyWOOption})
  joblist.unshift({...onlyJobsOption})
  joblist.unshift({...allJobOption})
  dispatch(updatePtoJob(joblist));
};

export const { 
  updateFilter, updateNewJobs, updateJobs, selectedJobs, setRequestNumber, 
  copyFromJobs, updateWo, updatePtoJob, setSelectedWO, setPreviousUrl, setCreatedJob,
  setLoader, setJobCreatedDetails, setExistJob, setSelectFirstJob, setSelectedJobPto
} = jobsSlice.actions;

export const jobsState = (state) => {
    return state.jobs;
};

export default jobsSlice.reducer;