import { Provider } from 'react-redux'
import React from 'react'
import { AppRouter } from 'utils/router' 
import store from './store/store'
import {Provider as AlertProvider} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './assets/scss/main.scss';

const alertOptions = {
  timeout: 8000,
  position: "top right"
}
const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <AppRouter />
        </AlertProvider>
      </Provider>
    </React.StrictMode>
  )
}

export default App
