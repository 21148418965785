import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'

export const jobFloorsInitialState = {
    loading: false,
    filter: {
        job_id: '',
        page: 1,
        size: 10,
        sort_by: '',
        sort_order: 'desc',
    },
    jobFloors: {
        totalPages: 1,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        perPage: 2,
        to: 20,
        total: 20,
        data: [],
    },
    currentFloor : {},
    formResponse : {},
    deleteResponse:{},
    floorsOptList: [],
}

export const jobFloorsSlice = createSlice({
    name: 'jobFloors',
    initialState: jobFloorsInitialState,
    reducers: {
        filters: (state, action) => {
            state.filter = action.payload
            state.loader = true
        },
        setFloors: (state, action) => {
            state.jobFloors = action.payload.data
            state.loader = false
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setFloorFormSubmitted: (state, action) => {
            state.formResponse = action.payload;
            state.loader = false;
        },
        setFloorDeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
        setCurrentFloor: (state,action) => {
            state.currentFloor = action.payload
        },
        setListOptionsFloors: (state, action) => {
            state.floorsOptList = action.payload.data
            state.loader = false
        },
    },
})

export const getFloorsList = (filter) => async (dispatch) => {
    dispatch(filters(filter))
    dispatch(setLoader(true))
    let data = {...filter}
    const response = await clientService.post('job/floors/list', data)
    dispatch(setFloors(response.data.data))
}

export const getFloorsOptions = (data) => async (dispatch) => {
    const response = await clientService.post("job/floors/dropdown", data);
    dispatch(setListOptionsFloors(response.data));
};

export const addFloor = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/floors", data);
    dispatch(setFloorFormSubmitted(response.data))
};

export const editFloor = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("job/floors/"+data.id, data);
    dispatch(setFloorFormSubmitted(response.data))
    dispatch(setLoader(false));
};

export const deleteFloor = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("job/floors/destroy/" + id);
    dispatch(setFloorDeleteResponse(response.data));
};

export const deleteFloorMultiple = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("job/floors/multiple-destroy" , data);
    dispatch(setFloorDeleteResponse(response.data))
};

export const {
    setFloors,
    filters,
    setLoader,
    setFloorFormSubmitted,
    setFloorDeleteResponse,
    setCurrentFloor,
    setListOptionsFloors
} = jobFloorsSlice.actions

export const jobFloorsState = (state) => {
    return state.jobFloors
}

export default jobFloorsSlice.reducer
