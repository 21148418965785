import { createSlice } from '@reduxjs/toolkit'
import config from 'config'

import clientService from '../../services/service'
export const allPtoEmployee = {value: -1, label: 'All Employees'}
let obortApiController, getEmptListController;

export const ptoCalendarInitialState = {
    loading: false,
    filter: {
        departnmentIds : [],
        job_id : '-1',
        start_date: '',
        end_date: '',
        filter_type: 0,
        employeeIds: [-1]
    },
    formResponse:{},
    jobOptionsList: [],
    empOptionsPtoList: [],
    empList: [],
    empJobList: [],
    userList: [],
    currentPtoReq : {},
    currentPtoReqComment : {},
    selectedPtoEmployees: [],
    deleteResponse: {},
    currentpto : {},
    ptoRequestList: [],
    allEmpIds: []
}

export const ptoCalendarSlice = createSlice({
    name: 'ptoCalendars',
    initialState: ptoCalendarInitialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        filters: (state, action) => {
            state.filter = action.payload
        },
        tableFilters: (state, action) => {
            state.tableFilter = action.payload
        },
        setCurrentPtoReq: (state, action) => {
            state.currentPtoReq = action.payload
        },
        setCurrentPto: (state, action) => {
            state.currentpto = action.payload
        },
        setAllEmpIds: (state, action) => {
            state.allEmpIds = action.payload
        },
        setPtoRequestList: (state, action) => {
            state.ptoRequestList = action.payload.data
            state.userList = action.payload.userList
            state.loader = false
        },
        setPtoReqComment: (state, action) => {
            state.currentPtoReqComment = action.payload.data
            state.loader = false
        },
        setJobOptiosList:(state, action) => {
            state.jobOptionsList = action.payload.data
            state.loader = false
        },
        setEmpOptiosPtoList:(state, action) => {
            state.empOptionsPtoList = action.payload.data
            state.loader = false
        },
        setEmpList:(state, action) => {
            state.empList = action.payload.data
        },
        setSelectedPtoEmployees:(state, action) => {
            state.selectedPtoEmployees = action.payload
        },
        setEmpJobList:(state, action) => {
            state.empJobList = action.payload.data
        },
        setFormSubmitted: (state, action) => {
            state.formResponse = action.payload;
            state.loader = false;
        },
        setPTODeleteResponse: (state, action) => {
            state.deleteResponse = action.payload;
            state.loader = false;
        },
    },
})

export const getPtoList = (filter) => async (dispatch, getState) => {
    if (obortApiController) {
        obortApiController.abort()
    }
    obortApiController = new AbortController();
    dispatch(setLoader(true))
    let filtr = {...filter}
    filtr.employeeIds = filtr.employeeIds.indexOf(-1) > -1 ? [] : filtr.employeeIds
    const response = await clientService.post('pto-calendar/list', filtr, {signal:  obortApiController.signal})
    if (response && response.data && response.data.data) {
        dispatch(setPtoRequestList(response.data.data))
    }
}

export const getPtoReqComment = (id) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.get("pto-calendar/get-comment/"+id);
    dispatch(setPtoReqComment(response.data.data));
};

export const getEmpJobList = (empId) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post("job/get-emp-job", {empId: empId});
    dispatch(setEmpJobList(response.data));
    dispatch(setLoader(false))
};

export const addPTORequest = (postData) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("pto-calendar/create", postData);
    dispatch(setFormSubmitted(response.data))
};

export const updatePTORequest = (postData) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.put("pto-calendar/"+postData.id, postData.value);
    dispatch(setFormSubmitted(response.data))
};

export const getJobOptionsList = (type) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.get("job/jobOptionsList/"+type);
    dispatch(setJobOptiosList(response.data));
};

export const setEmployPtoSelected = (data) => async (dispatch) => {
    dispatch(setSelectedPtoEmployees(data));
};

export const getEmpOptionsPtoList = (departmentId) => async (dispatch, getState) => {
    if (getEmptListController) {
        getEmptListController.abort()
    }
    getEmptListController = new AbortController();
    let url = 'employee/empOptionsList'
    if (departmentId) {
        url = url + "?departmentId=" + departmentId
    }
    const response = await clientService.get(url, {signal: getEmptListController.signal});
    let emps = response?.data?.data || [];
    // let ids = []
    // if (emps && emps?.length) {
    //     ids = emps.map(a => a.value)
    // }
    dispatch(setSelectedPtoEmployees([allPtoEmployee]));
    // dispatch(setAllEmpIds(ids));
    emps.unshift(allPtoEmployee)
    dispatch(setEmpOptiosPtoList({data: emps}));
};

export const deletePTORequest = (id) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.remove("pto-calendar/destroy/"+id);
    dispatch(setPTODeleteResponse(response.data));
};

export const {
    setLoader,
    filters,
    tableFilters,
    setPtoRequestList,
    setCurrentPtoReq,
    setPtoReqComment,
    setJobOptiosList,
    setEmpOptiosPtoList,
    setSelectedPtoEmployees,
    setptoFormSubmitted,
    setAllEmpIds,
    setLoadEmployee,
    setEmpList,
    setEmpJobList,
    setFormSubmitted,
    setPTODeleteResponse
} = ptoCalendarSlice.actions

export const ptoCalendarState = (state) => {
    return state.ptoCalendars
}

export default ptoCalendarSlice.reducer